import { IonApp, IonLoading, IonRouterOutlet, setupConfig } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { lazy, Suspense } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Redirect, Route } from 'react-router-dom'
import AuthRoute from './components/AuthRoute'
import EnvCheckErrorBoundary from './components/EnvCheckErrorBoundary'
import PartySubscriptionWrapper from './components/PartySubscriptionWrapper'
import useAppStateChange from './hooks/useAppStateChange'
import useUserRecordLocation from './hooks/useUserRecordLocation'
import './main.css'
import * as routes from './routes'
/* Theme variables */
import MaintenanceModal from './components/MaintenanceModal'
import NotSupportedClientModal from './components/NotSupportedClientModal'
import QueriesErrorModal from './components/QueriesErrorModal'
import useAppsFlyer from './hooks/useAppsFlyer'
import useDeepLink from './hooks/useDeepLink'
import useDeviceInfo from './hooks/useDeviceInfo'
import useEventBus from './hooks/useEventBus'
import useOneSignal from './hooks/useOneSignal'
import useRemoteConfigServices from './hooks/useRemoteConfig'
import './theme/variables.css'

const MainTabs = lazy(() => import('./MainTabs'))
const ErrorPage = lazy(() => import('./pages/ErrorPage'))
const VideoTutorialPage = lazy(() => import('./pages/VideoTutorialPage'))
const GreetingPage = lazy(() => import('./pages/GreetingPage'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const LogoutPage = lazy(() => import('./pages/LogoutPage'))
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'))
const SignupPage = lazy(() => import('./pages/SignupPage'))
const StartPage = lazy(() => import('./pages/StartPage'))
const ChallengePageWrapper = lazy(
  () => import('./pages/challenge/ChallengePageWrapper')
)
const CreatePartyPageWrapper = lazy(
  () => import('./pages/createPartyPage/CreatePartyPageWrapper')
)
const CreateSoloPartyPage = lazy(() => import('./pages/CreateSoloPartyPage'))
const DailyChallengePage = lazy(() => import('./pages/DailyChallengePage'))
const DailyChallengeResultsPage = lazy(
  () => import('./pages/DailyChallengeResultsPage')
)
const PartyOptionPage = lazy(() => import('./pages/PartyOptionPage'))
const PartyWaitingPage = lazy(() => import('./pages/PartyWaitingPage'))
const ScourLoadingPage = lazy(() => import('./pages/ScourLoadingPage'))
const ScourPaymentPage = lazy(() => import('./pages/ScourPaymentPage'))
const ScourSummaryPage = lazy(() => import('./pages/ScourSummaryPage'))
const StopMapPageWrapper = lazy(
  () => import('./pages/stopMapPage/StopMapPageWrapper')
)
const StopPage = lazy(() => import('./pages/StopPage'))
const CityPickPage = lazy(() => import('./pages/CityPickPage'))

//@ts-ignore
//without next lines mapbox doesn't work on some android devices
//eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

setupConfig({
  swipeBackEnabled: false,
  animated: false,
  mode: 'ios',
})

const App: React.FC = () => {
  const { clientSupported, maintenanceMode, VersionService } =
    useRemoteConfigServices()
  const { showQueriesError } = useEventBus()
  useDeepLink()
  useOneSignal()
  useAppsFlyer()
  useDeviceInfo()
  useUserRecordLocation()
  useAppStateChange()

  if (!clientSupported)
    return <NotSupportedClientModal versionService={VersionService} />
  if (maintenanceMode) return <MaintenanceModal />

  return (
    <>
      {showQueriesError && <QueriesErrorModal />}
      <PartySubscriptionWrapper />
      <IonApp>
        <EnvCheckErrorBoundary>
          <IonReactRouter>
            <Suspense fallback={<IonLoading isOpen={true} />}>
              <IonRouterOutlet>
                {/* Auth */}
                <Route exact path={routes.VIDEO_TUTORIAL}>
                  <VideoTutorialPage />
                </Route>
                <Route exact path={routes.GREETING}>
                  <GreetingPage />
                </Route>
                <Route exact path={routes.LOGIN_EMAIL}>
                  <LoginPage />
                </Route>
                <Route exact path={routes.LOGOUT}>
                  <LogoutPage />
                </Route>
                <Route exact path={routes.RESET}>
                  <ResetPasswordPage />
                </Route>
                <Route exact path={routes.SIGNUP}>
                  <SignupPage />
                </Route>
                <Route exact path={routes.START}>
                  <StartPage />
                </Route>

                {/* Scour flow */}
                <Route exact path={routes.SCOUR_PARTY_SOLO}>
                  <CreateSoloPartyPage />
                </Route>
                <Route exact path={routes.SCOUR_STOP}>
                  <StopPage />
                </Route>
                <Route exact path={routes.SCOUR_STOP_MAP}>
                  <StopMapPageWrapper />
                </Route>
                <Route exact path={routes.SCOUR_CHALLENGE}>
                  <ChallengePageWrapper />
                </Route>
                <Route exact path={routes.SCOUR_END}>
                  <ScourSummaryPage />
                </Route>
                <Route exact path={routes.SCOUR_LOADING}>
                  <ScourLoadingPage />
                </Route>
                {/* Other */}
                <Route exact path={routes.SCOUR_PARTY_WAITING}>
                  <PartyWaitingPage />
                </Route>

                <Route exact path={routes.SCOUR_PARTY_OPTION}>
                  <PartyOptionPage />
                </Route>

                <Route exact path={routes.SCOUR_PAYMENT}>
                  <ScourPaymentPage />
                </Route>

                <Route exact path={routes.SCOUR_PARTY_CREATE}>
                  <CreatePartyPageWrapper />
                </Route>

                <Route exact path={routes.DAILY_CHALLENGE_RESULTS}>
                  <DailyChallengeResultsPage />
                </Route>
                <Route exact path={routes.DAILY_CHALLENGE}>
                  <DailyChallengePage />
                </Route>
                <Route exact path={routes.CITY_PICK}>
                  <CityPickPage />
                </Route>

                {/* Tabs */}
                <AuthRoute path={routes.TABS}>
                  <MainTabs />
                </AuthRoute>
                <AuthRoute exact path={routes.ROOT}>
                  <Redirect to={routes.TABS} />
                </AuthRoute>

                <Route>
                  <ErrorPage />
                </Route>
              </IonRouterOutlet>
            </Suspense>
          </IonReactRouter>
        </EnvCheckErrorBoundary>
      </IonApp>
    </>
  )
}
export default React.memo(App)
