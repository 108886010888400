import styled from 'styled-components'

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme: { zIndex } }) => zIndex || '251'};
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: ${({ theme: { background } }) => {
    if (background === 'blue') return 'var(--modal-background-blue)'
    if (background === 'light-blue') return 'var(--blurred-answer)'
    if (background === 'transparent') return 'transparent'
    else return 'var(--modal-background)'
  }};

  transition: ${({ theme: { transition } }) => transition};

  opacity: ${({ theme: { opacity } }) => opacity};
  visibility: ${({ theme: { visibility } }) => visibility};
`

export default ModalBackground
