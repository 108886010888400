import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  SignInMethod,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { EventBus } from '../eventBus'
import {
  READ_ATTEMPTS_FOR_FIVE_MINUTES,
  READ_ATTEMPTS_LIMIT,
  WRITE_ATTEMPTS_FOR_FIVE_MINUTES,
  WRITE_ATTEMPTS_LIMIT,
} from './../config'
import { auth, firebase } from './../firebase'

export const FirebaseSignInMethod: typeof SignInMethod & {
  APPLE: 'apple.com'
} = {
  ...SignInMethod,
  APPLE: 'apple.com',
}

export const FIVE_MINUTE_IN_MILLISECONDS = 300000

export const WRITE_BUCKET_EMPTY_EVENT = 'WRITE_BUCKET_EMPTY_EVENT'
export const WRITE_BUCKET_NOT_EMPTY_EVENT = 'WRITE_BUCKET_NOT_EMPTY_EVENT'
export const READ_BUCKET_EMPTY_EVENT = 'READ_BUCKET_EMPTY_EVENT'
export const READ_BUCKET_NOT_EMPTY_EVENT = 'READ_BUCKET_NOT_EMPTY_EVENT'

let readAttemptsLeft = READ_ATTEMPTS_LIMIT
let writeAttemptsLeft = WRITE_ATTEMPTS_LIMIT

export function increaseReadAttempts() {
  if (readAttemptsLeft <= 1) {
    EventBus.publish(READ_BUCKET_EMPTY_EVENT)
    return
  }

  readAttemptsLeft -= 1
}

export function increaseWriteAttempts() {
  if (writeAttemptsLeft <= 1) {
    EventBus.publish(WRITE_BUCKET_EMPTY_EVENT)
    return
  }

  writeAttemptsLeft -= 1
}

setInterval(() => {
  if (readAttemptsLeft + READ_ATTEMPTS_FOR_FIVE_MINUTES > READ_ATTEMPTS_LIMIT) {
    readAttemptsLeft = READ_ATTEMPTS_LIMIT
  } else {
    readAttemptsLeft += READ_ATTEMPTS_FOR_FIVE_MINUTES
    EventBus.publish(READ_BUCKET_NOT_EMPTY_EVENT)
  }

  if (
    writeAttemptsLeft + WRITE_ATTEMPTS_FOR_FIVE_MINUTES >
    WRITE_ATTEMPTS_LIMIT
  ) {
    writeAttemptsLeft = WRITE_ATTEMPTS_LIMIT
  } else {
    writeAttemptsLeft += WRITE_ATTEMPTS_FOR_FIVE_MINUTES
    EventBus.publish(WRITE_BUCKET_NOT_EMPTY_EVENT)
  }
}, FIVE_MINUTE_IN_MILLISECONDS)

export class FirebaseService {
  static storage = (): firebase.storage.Reference => {
    increaseReadAttempts()
    return firebase.storage().ref()
  }

  static storageChild = (name: string) => {
    increaseReadAttempts()
    return this.storage().child(name)
  }

  static signIn = async (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password)

  static signOut = async () => {
    await Promise.all([signOut(auth), FirebaseAuthentication.signOut()])
  }

  static createUser = async (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password)

  static getSignInMethods = async (email: string): Promise<string[]> =>
    fetchSignInMethodsForEmail(auth, email)

  static resetPassword = async (email: string) =>
    sendPasswordResetEmail(auth, email)

  static fetchSignInMethodsForEmail = async (
    email: string
  ): Promise<string[]> => fetchSignInMethodsForEmail(auth, email)
}
