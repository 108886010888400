import styled from 'styled-components'

const Description = styled.p`
  margin: 15px 0 30px;
  font-size: 22px;
  line-height: 1.27;
  color: var(--white);
  text-align: center;
  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    font-size: 18px;
  }
`

export default Description
