import styled from 'styled-components'

const DescriptionTitle = styled.span`
  font-size: 36px;
  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    font-size: 30px;
  }
`

export default DescriptionTitle
