import React, { Dispatch, SetStateAction, useState } from 'react'
import { CityCouponQuery, PausedAtPage } from '../generated'
import { DefaultStartPageProps } from '../util'

export interface ContextType {
  showTabs: boolean
  setShowTabs: Dispatch<SetStateAction<boolean>>
  dailySkipped: boolean
  setDailySkipped: Dispatch<SetStateAction<boolean>>
  scoursCoupon: string
  setScoursCoupon: Dispatch<SetStateAction<string>>
  cityCoupon: string
  setCityCoupon: Dispatch<SetStateAction<string>>
  accessCode: string
  setAccessCode: Dispatch<SetStateAction<string>>
  cityModalClosed: boolean
  setCityModalClosed: Dispatch<SetStateAction<boolean>>
  cityCouponData: CityCouponQuery['cityCoupon']
  setCityCouponData: Dispatch<SetStateAction<CityCouponQuery['cityCoupon']>>
  startScourPage: DefaultStartPageProps
  setStartScourPage: Dispatch<SetStateAction<DefaultStartPageProps>>
}

export const Context = React.createContext<ContextType | undefined>(undefined)

const defaultStartPage: DefaultStartPageProps = {
  stopIndex: 0,
  challengeIndex: 0,
  stoppedAtPage: PausedAtPage.Map,
}

export const UIProvider: React.FC = ({ children }) => {
  const [showTabs, setShowTabs] = useState(true)
  const [dailySkipped, setDailySkipped] = useState(false)

  const [scoursCoupon, setScoursCoupon] = useState('')
  const [cityCoupon, setCityCoupon] = useState('')
  const [accessCode, setAccessCode] = useState('')

  const [cityModalClosed, setCityModalClosed] = useState(false)
  const [cityCouponData, setCityCouponData] =
    useState<CityCouponQuery['cityCoupon']>(null)
  const [startScourPage, setStartScourPage] = useState(defaultStartPage)

  let state = {
    showTabs,
    setShowTabs,
    dailySkipped,
    setDailySkipped,
    scoursCoupon,
    setScoursCoupon,
    cityCoupon,
    setCityCoupon,
    accessCode,
    setAccessCode,
    cityModalClosed,
    setCityModalClosed,
    cityCouponData,
    setCityCouponData,
    startScourPage,
    setStartScourPage,
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export default Context as React.Context<ContextType>
