import { App as AppToListen, URLOpenListenerEvent } from '@capacitor/app'
import { useCallback, useContext, useEffect } from 'react'
import UIContext from '../context/context'

const useDeepLink = () => {
  const { setScoursCoupon, setCityCoupon, setAccessCode } =
    useContext(UIContext)

  useEffect(() => {
    AppToListen.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      checkSlug(event.url)
    })
    AppToListen.getLaunchUrl().then((value) => {
      if (value?.url) checkSlug(value.url)
    })

    return () => {
      AppToListen.removeAllListeners()
    }
  }, [])

  const checkSlug = useCallback((url: string) => {
    const slug = url.split('/app/').pop()

    if (slug?.includes('redeemedCoupon')) {
      setScoursCoupon(slug.split('redeemedCoupon-').pop() || '')
    }
    if (slug?.includes('redeemedCityCoupon')) {
      setCityCoupon(slug.split('redeemedCityCoupon-').pop() || '')
    }
    if (slug?.includes('redeemedAccessCode')) {
      setAccessCode(slug.split('redeemedAccessCode-').pop() || '')
    }
  }, [])
}

export default useDeepLink
