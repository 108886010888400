import Message from './JoinParty/Message'
import Modal from './JoinParty/Modal'
import ModalTitle from './JoinParty/ModalTitle'
import ModalBackgroundComponent from './Modal/ModalBackgroundComponent'

const MaintenanceModal = () => (
  <ModalBackgroundComponent classnames={{ background: 'blue' }}>
    <Modal>
      <ModalTitle>City Scour Currently Unavailable</ModalTitle>
      <Message theme="no-margin-bottom">
        City Scour is currently unavailable. We are currently working on a fix,
        and apologize for any inconvenience in the mean time. Please check the
        app again soon.
      </Message>
    </Modal>
  </ModalBackgroundComponent>
)

export default MaintenanceModal
