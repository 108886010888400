import { useLazyQuery } from '@apollo/client'
import { User as FirebaseUser } from 'firebase/auth'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { MeDocument, MeQuery } from '../generated'
import { useNullableUser } from '../util'

export interface UserContext {
  exists: boolean | null
  user: FirebaseUser | null
  currentUser: MeQuery['me'] | null | undefined
  shouldBeRedirected: boolean
  setShouldBeRedirected: Dispatch<SetStateAction<boolean>>
  updateUser: () => Promise<void>
}

const Context = createContext<UserContext>({
  exists: false,
  user: null,
  currentUser: null,
  shouldBeRedirected: true,
  setShouldBeRedirected: () => {},
  updateUser: async () => {},
})

export const UserContextProvider: FC = ({ children }) => {
  const [user, userLoading] = useNullableUser()
  const [getUser, { loading: docLoading, error, data }] = useLazyQuery(
    MeDocument,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [currentUser, setCurrentUser] = useState<MeQuery['me'] | null>(null)
  const [shouldBeRedirected, setShouldBeRedirected] = useState(true)
  const [exists, setExists] = useState<boolean | null>(false)

  const updateUser = useCallback(async () => {
    const res = await getUser()
    setCurrentUser(res.data?.me ?? null)
  }, [])

  useEffect(() => {
    if (!user) return
    updateUser()
  }, [user])

  useEffect(() => {
    if (
      userLoading ||
      (user && !currentUser && !data && docLoading && !error)
    ) {
      setExists(null)
      return
    }
    if (
      !user ||
      (!currentUser && !data?.me) ||
      (currentUser && user.uid !== currentUser.id)
    ) {
      setExists(false)
      return
    }
    setExists(true)
  }, [userLoading, user, docLoading, currentUser, data, error])

  const state: UserContext = {
    exists,
    user,
    currentUser,
    shouldBeRedirected,
    setShouldBeRedirected,
    updateUser,
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
export const useUserContext = () => useContext(Context)
