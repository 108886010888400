import Message from './JoinParty/Message'
import Modal from './JoinParty/Modal'
import ModalBackgroundComponent from './Modal/ModalBackgroundComponent'

const QueriesErrorModal = () => (
  <ModalBackgroundComponent classnames={{ background: 'blue' }}>
    <Modal>
      <Message theme="no-margin-bottom">
        Database query limit reached over, please wait...
      </Message>
    </Modal>
  </ModalBackgroundComponent>
)

export default QueriesErrorModal
