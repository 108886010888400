import { useLazyQuery } from '@apollo/client'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { PartyDocument, PartyQuery } from '../generated'

export interface PartyContext {
  party: PartyQuery['party'] | undefined
  partyId: string | null
  setPartyId: Dispatch<SetStateAction<string | null>>
}

const Context = createContext<PartyContext>({
  party: undefined,
  partyId: null,
  setPartyId: () => {},
})

export const PartyContextProvider: FC = ({ children }) => {
  const [partyId, setPartyId] = useState<string | null>(null)
  const [party, setParty] = useState<PartyQuery['party'] | null>(null)

  const [getParty] = useLazyQuery(PartyDocument, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!partyId) return

    const updateParty = () => {
      getParty({
        variables: {
          partyId,
        },
      })
        .then((res) => setParty(res.data?.party))
        .catch((e) => console.log(e.message))
    }

    updateParty()

    const interval = setInterval(() => {
      updateParty()
    }, 3000)

    return () => clearInterval(interval)
  }, [partyId])

  const state: PartyContext = {
    party,
    partyId,
    setPartyId,
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
export const usePartyContext = () => useContext(Context)
