import { useEffect, useState } from 'react'
import { EventBus } from '../eventBus'
import {
  READ_BUCKET_EMPTY_EVENT,
  READ_BUCKET_NOT_EMPTY_EVENT,
  WRITE_BUCKET_EMPTY_EVENT,
} from '../services/firebaseService'

const useEventBus = () => {
  const [readBucketEmpty, setReadBucketEmpty] = useState(false)
  const [writeBucketEmpty, setWriteBucketEmpty] = useState(false)
  const showQueriesError = readBucketEmpty || writeBucketEmpty

  useEffect(() => {
    const readBucketEmptyListener = () => setReadBucketEmpty(true)
    EventBus.subscribe(READ_BUCKET_EMPTY_EVENT, readBucketEmptyListener)

    const writeBucketEmptyListener = () => setWriteBucketEmpty(true)
    EventBus.subscribe(WRITE_BUCKET_EMPTY_EVENT, writeBucketEmptyListener)

    const readBucketNotEmptyListener = () => setReadBucketEmpty(false)
    EventBus.subscribe(READ_BUCKET_NOT_EMPTY_EVENT, readBucketNotEmptyListener)

    const writeBucketNotEmptyListener = () => setWriteBucketEmpty(false)
    EventBus.subscribe(READ_BUCKET_NOT_EMPTY_EVENT, writeBucketNotEmptyListener)

    return () => {
      EventBus.unsubscribe(READ_BUCKET_EMPTY_EVENT, readBucketEmptyListener)
      EventBus.unsubscribe(WRITE_BUCKET_EMPTY_EVENT, writeBucketEmptyListener)
      EventBus.unsubscribe(
        READ_BUCKET_NOT_EMPTY_EVENT,
        readBucketNotEmptyListener
      )
      EventBus.unsubscribe(
        READ_BUCKET_NOT_EMPTY_EVENT,
        writeBucketNotEmptyListener
      )
    }
  }, [])

  return { showQueriesError }
}

export default useEventBus
