import * as Sentry from '@sentry/react'
import React from 'react'
import { ENVIRONMENT, REACT_APP_OVERRIDE_LOGGING } from '../config'
import ErrorPage from '../pages/ErrorPage'
import ErrorBoundary from './ErrorBoundary'

const EnvCheckErrorBoundary: React.FC = ({ children }) => {
  const envCheck = ENVIRONMENT === 'production' || REACT_APP_OVERRIDE_LOGGING
  return envCheck ? (
    <Sentry.ErrorBoundary fallback={<ErrorPage crash={true} />}>
      {children}
    </Sentry.ErrorBoundary>
  ) : (
    <ErrorBoundary>{children}</ErrorBoundary>
  )
}

export default EnvCheckErrorBoundary
