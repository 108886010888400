import { useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { useGeolocationContext } from '../context/geolocationContext'
import { useUserContext } from '../context/userContext'
import { AddUserOccasionalLocationDocument } from '../generated'

const useUserRecordLocation = () => {
  const { geolocation } = useGeolocationContext()
  const { currentUser } = useUserContext()

  const [userLocationWasSaved, setUserLocationWasSaved] = useState(false)

  const [saveUserOccasionalLocation] = useMutation(
    AddUserOccasionalLocationDocument
  )

  const saveUserLocation = useCallback(() => {
    if (!geolocation || typeof geolocation === 'string' || !currentUser?.id)
      return

    saveUserOccasionalLocation({
      variables: {
        input: {
          geolocation: {
            latitude: geolocation.latitude,
            longitude: geolocation.longitude,
            timestamp: new Date(),
          },
        },
      },
    }).catch((e) => console.log(e.message))
  }, [geolocation, currentUser?.id])

  useEffect(() => {
    if (userLocationWasSaved || !geolocation || typeof geolocation === 'string')
      return
    setUserLocationWasSaved(true)
    saveUserLocation()
  }, [userLocationWasSaved, geolocation, saveUserLocation])

  return saveUserLocation
}

export default useUserRecordLocation
