import { useContext, useEffect } from 'react'
import UIContext from '../context/context'

const useHideTabs = () => {
  const { setShowTabs } = useContext(UIContext)

  useEffect(() => {
    setShowTabs(false)
  }, [])
}

export default useHideTabs
