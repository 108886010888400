import { Device } from '@capacitor/device'
import { useEffect } from 'react'
import { APP_VERSION } from '../config'
import { useUserContext } from '../context/userContext'
import { mixpanelWrapper, sentryWrapper } from '../util'

const useDeviceInfo = () => {
  const { currentUser: user } = useUserContext()

  useEffect(() => {
    if (!user) return

    const setDeviceData = async () => {
      const [device, deviceId] = await Promise.all([
        Device.getInfo(),
        Device.getId(),
      ])

      mixpanelWrapper().people.set({
        'App Version': APP_VERSION,
        'Device Name': device.name,
        'Device Model': device.model,
        'Device OS': device.operatingSystem,
        'Device OS Version': device.osVersion,
        'Device Manufacturer': device.manufacturer,
        'Device UUID': deviceId.uuid,
        'User ID': user.id,
      })
    }

    mixpanelWrapper().identify(user.id)
    sentryWrapper().setUser({
      id: user.id,
      username: user.username,
      email: user.email || undefined,
      ip_address: '{{auto}}',
    })
    setDeviceData()
  }, [user?.id])
}

export default useDeviceInfo
