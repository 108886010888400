import styled from 'styled-components'

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 35px 25px;

  height: ${({ theme }) => {
    if (theme === 'navigation') return '95%'
  }};

  display: ${({ theme }) => theme === 'waiting' && 'flex'};
  align-items: ${({ theme }) => theme === 'waiting' && 'center'};
  flex-direction: ${({ theme }) => theme === 'waiting' && 'column'};

  text-align: ${({ theme }) => theme === 'waiting' && 'center'};

  background-color: var(--white);
  border: 4px solid var(--light-peach);
  border-radius: 20px;

  @media screen and (max-height: 667px), screen and (max-width: 374px) {
    padding: 25px 15px;
  }

  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    padding: 15px;
  }
`

export default Modal
