import styled from 'styled-components'

const Message = styled.p`
  margin-top: ${({ theme }) =>
    theme === 'navigation' || theme === 'margin-top' ? '20px' : '0'};
  margin-bottom: ${({ theme }) =>
    theme === 'no-margin-bottom' ? '0' : '20px'};
  color: ${({ theme }) =>
    theme === 'accented' ? 'var(--medium-orange)' : 'var(--more-medium-blue)'};
  text-align: ${({ theme }) => (theme === 'no-center' ? 'start' : 'center')};
  font-size: ${({ theme }) => {
    if (theme === 'font-size') return '22px'
    else return '16px'
  }};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-height: 667px), screen and (max-width: 374px) {
    font-size: ${({ theme }) => {
      if (theme === 'font-size') return '20px'
      else return '14px'
    }};
  }
`

export default Message
