import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const ErrorIcon = styled(FontAwesomeIcon)`
  color: var(--white);
  width: 60px;
  height: 60px;

  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    fwidth: 50px;
    height: 50px;
  }
`

export default ErrorIcon
