import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
}

export type Achievement = {
  __typename?: 'Achievement'
  amount?: Maybe<Scalars['Int']>
  description: Scalars['String']
  hasPin?: Maybe<Scalars['Boolean']>
  iconUrl: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  redeemed?: Maybe<Scalars['Boolean']>
  requiredScourIds: Array<Scalars['ID']>
  requiredScours: Array<Scour>
  scourId?: Maybe<Scalars['ID']>
  userId?: Maybe<Scalars['ID']>
}

export type ActivateCityCouponInput = {
  couponId: Scalars['ID']
  scourId: Scalars['ID']
}

export type ActivateDefaultCouponInput = {
  couponId: Scalars['ID']
}

export type AddAchievementInput = {
  amount?: InputMaybe<Scalars['Int']>
  description: Scalars['String']
  hasPin?: InputMaybe<Scalars['Boolean']>
  icon: Scalars['String']
  name: Scalars['String']
  redeemed?: InputMaybe<Scalars['Boolean']>
  requiredScourIds: Array<Scalars['ID']>
  scourId?: InputMaybe<Scalars['ID']>
  userId?: InputMaybe<Scalars['ID']>
}

export type AddArticleChallengeInput = {
  articleId: Scalars['ID']
  challenge: ChallengeInput
}

export type AddArticleInput = {
  body: Scalars['String']
  challenges?: InputMaybe<Array<ChallengeInput>>
  hidden: Scalars['Boolean']
  images: Array<Scalars['String']>
  name: Scalars['String']
  summary?: InputMaybe<Array<Scalars['String']>>
}

export type AddCityInput = {
  articleIds?: InputMaybe<Array<Scalars['ID']>>
  geoPoint: GeoPointInput
  hidden: Scalars['Boolean']
  name: Scalars['String']
  radius: Scalars['Int']
  scourIds?: InputMaybe<Array<Scalars['ID']>>
}

export type AddPartyApprovedLocationInput = {
  challengeId: Scalars['ID']
  partyId: Scalars['ID']
}

export type AddPaymentInput = {
  pinsAmount: Scalars['Int']
  purchaseId: Scalars['ID']
  scourId: Scalars['ID']
}

export type AddScourCouponInput = {
  scourIds: Array<Scalars['ID']>
}

export type AddScourInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  beta: Scalars['Boolean']
  description: TextContentInput
  discounted?: InputMaybe<Scalars['Boolean']>
  distance?: InputMaybe<Scalars['Float']>
  duration: Scalars['Int']
  free?: InputMaybe<Scalars['Boolean']>
  hidden?: InputMaybe<Scalars['Boolean']>
  level: Level
  name: Scalars['String']
  popups: Array<PopupInput>
  stops: Array<StopInput>
  tags: Array<Scalars['String']>
}

export type AddScourStopInput = {
  scourId: Scalars['ID']
  stop: StopInput
}

export type AddStopChallengeInput = {
  challenge: ChallengeInput
  stopId: Scalars['ID']
}

export type AddUserLocationInput = {
  geolocation: Array<PreciseGeoPointInput>
  partyId: Scalars['String']
}

export type AddUserOccasionalLocationInput = {
  geolocation: PreciseGeoPointInput
}

export type Address = {
  __typename?: 'Address'
  aptNumber?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type AddressInput = {
  aptNumber?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  state: Scalars['String']
  street: Scalars['String']
  zipCode: Scalars['String']
}

export type AllParty = {
  __typename?: 'AllParty'
  id: Scalars['String']
  scour: AllPartyScour
  users: Array<AllPartyUser>
}

export type AllPartyScour = {
  __typename?: 'AllPartyScour'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type AllPartyUser = {
  __typename?: 'AllPartyUser'
  id?: Maybe<Scalars['String']>
}

export type AnswerDailyChallengeMutationResponse = {
  __typename?: 'AnswerDailyChallengeMutationResponse'
  dailyChallenge: DailyChallengeWithExplanation
  myAnswer: DailyChallengeCompleted
  myDailyChallengesInfo: DailyChallengesInfo
}

export enum AnswerKind {
  FreeNumberAnswerSet = 'FreeNumberAnswerSet',
  FreeTextAnswerSet = 'FreeTextAnswerSet',
  MultipleChoiceAnswerSet = 'MultipleChoiceAnswerSet',
}

export type AnswerSet =
  | FreeNumberAnswerSet
  | FreeTextAnswerSet
  | MultipleChoiceAnswerSet

export type AnswerSetInput = {
  correct: Array<Scalars['String']>
  kind: AnswerKind
  options?: InputMaybe<Array<Scalars['String']>>
}

export type Article = {
  __typename?: 'Article'
  body: Scalars['String']
  challenges?: Maybe<Array<Challenge>>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  imageUrls: Array<Scalars['String']>
  name: Scalars['String']
  summary?: Maybe<Array<Scalars['String']>>
}

export type ArticleQuiz = {
  __typename?: 'ArticleQuiz'
  answers?: Maybe<Array<Maybe<QuizAnswer>>>
  article: Article
}

export type BugReport = {
  __typename?: 'BugReport'
  createdAt: Scalars['DateTime']
  description: Scalars['String']
  locationId?: Maybe<Scalars['ID']>
  scourId?: Maybe<Scalars['ID']>
  type: Scalars['String']
  user: User
  userId: Scalars['ID']
}

export type BugReportInput = {
  description: Scalars['String']
  locationId?: InputMaybe<Scalars['ID']>
  scourId?: InputMaybe<Scalars['ID']>
  type: Scalars['String']
}

export type Challenge = {
  __typename?: 'Challenge'
  answerSet: AnswerSet
  explanation?: Maybe<Scalars['String']>
  hint?: Maybe<Hint>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  navigation?: Maybe<ChallengeNavigation>
  order: Scalars['Int']
  question: Scalars['String']
}

export type ChallengeInput = {
  answerSet: AnswerSetInput
  explanation?: InputMaybe<Scalars['String']>
  hint?: InputMaybe<HintInput>
  image?: InputMaybe<Scalars['String']>
  navigation?: InputMaybe<NavigationInput>
  question: Scalars['String']
}

export type ChallengeNavigation = {
  __typename?: 'ChallengeNavigation'
  geolocation: GeoPoint
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type ChallengeWithExplanation = {
  __typename?: 'ChallengeWithExplanation'
  answerSet: AnswerSet
  explanation?: Maybe<Scalars['String']>
  hint?: Maybe<Hint>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  navigation?: Maybe<ChallengeNavigation>
  order: Scalars['Int']
  question: Scalars['String']
}

export type City = {
  __typename?: 'City'
  geofence: GeoFence
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  tiles: Array<Tile>
}

export type CityCoupon = {
  __typename?: 'CityCoupon'
  city: City
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  usageInfo: Array<CityCouponUsageInfo>
}

export type CityCouponUsageInfo = {
  __typename?: 'CityCouponUsageInfo'
  couponId: Scalars['ID']
  scour?: Maybe<Scour>
  usedAt: Scalars['DateTime']
  user: User
}

export type CityLocationInput = {
  description: TextContentInput
  geolocation: GeoPointInput
  lastStoryImg?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  stories?: InputMaybe<Array<StoryInput>>
}

export type Coupon = {
  __typename?: 'Coupon'
  id: Scalars['ID']
  scourIds: Array<Scalars['ID']>
  scours: Array<Scour>
  usageInfo?: Maybe<Array<ScourCouponUsageInfo>>
}

export enum CouponType {
  City = 'CITY',
  Scour = 'SCOUR',
}

export type CouponUsageInfo = {
  __typename?: 'CouponUsageInfo'
  couponId: Scalars['ID']
  usedAt: Scalars['DateTime']
  user: User
}

export type CreatePartyInput = {
  cityName: Scalars['String']
  scourId: Scalars['ID']
}

export type CreateUserInput = {
  email: Scalars['String']
  id: Scalars['ID']
  sendEmails: Scalars['Boolean']
  username: Scalars['String']
  zipCode?: InputMaybe<Scalars['String']>
}

export type DailyChallenge = {
  __typename?: 'DailyChallenge'
  date: Scalars['DateTime']
  usersCompleted: Array<DailyChallengeCompleted>
}

export type DailyChallengeCompleted = {
  __typename?: 'DailyChallengeCompleted'
  answer: StringOrInt
  correct: Scalars['Boolean']
  user: User
  userId: Scalars['ID']
  xp: Scalars['Int']
}

export type DailyChallengeWithExplanation = {
  __typename?: 'DailyChallengeWithExplanation'
  challenge: ChallengeWithExplanation
  date: Scalars['DateTime']
  usersCompleted: Array<DailyChallengeCompleted>
}

export type DailyChallengeWithoutExplanation = {
  __typename?: 'DailyChallengeWithoutExplanation'
  challenge: Challenge
  date: Scalars['DateTime']
  usersCompleted: Array<DailyChallengeCompleted>
}

export type DailyChallengesInfo = {
  __typename?: 'DailyChallengesInfo'
  correct: Scalars['Int']
  currentStreak: Scalars['Int']
  maxStreak: Scalars['Int']
  played: Scalars['Int']
}

export type DeleteArticleChallengeInput = {
  articleId: Scalars['ID']
  challengeId: Scalars['ID']
}

export type DeletePartyJoinResponse = {
  __typename?: 'DeletePartyJoinResponse'
  id: Scalars['ID']
}

export type DeleteScourStopInput = {
  scourId: Scalars['ID']
  stopId: Scalars['ID']
}

export type DeleteStopChallengeInput = {
  challengeId: Scalars['ID']
  stopId: Scalars['ID']
}

export type DiscountCode = {
  __typename?: 'DiscountCode'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  users?: Maybe<Array<Scalars['ID']>>
}

export type EndScourInput = {
  partyId: Scalars['ID']
  points: Scalars['Int']
  scourId: Scalars['ID']
}

export type EndScourResponse = {
  __typename?: 'EndScourResponse'
  scour: Scour
  scourId: Scalars['ID']
  user: User
  userId: Scalars['ID']
}

export type FreeNumberAnswerSet = {
  __typename?: 'FreeNumberAnswerSet'
  correct: Array<Scalars['Int']>
  kind: Scalars['String']
}

export type FreeTextAnswerSet = {
  __typename?: 'FreeTextAnswerSet'
  correct: Array<Scalars['String']>
  kind: Scalars['String']
}

export type GeoFence = {
  __typename?: 'GeoFence'
  geolocation: GeoPoint
  radius: Scalars['Float']
}

export type GeoPoint = {
  __typename?: 'GeoPoint'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type GeoPointInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type Hint = {
  __typename?: 'Hint'
  imageUrl?: Maybe<Scalars['String']>
  text: Scalars['String']
}

export type HintInput = {
  image?: InputMaybe<Scalars['String']>
  text: Scalars['String']
}

export type IntBox = {
  __typename?: 'IntBox'
  value: Scalars['Int']
}

export type LeaderboardRow = {
  __typename?: 'LeaderboardRow'
  points: Scalars['Int']
  user: User
}

export enum Level {
  Easy = 'EASY',
  Hard = 'HARD',
  Intermediate = 'INTERMEDIATE',
  Level_0_5 = 'LEVEL_0_5',
  Level_1 = 'LEVEL_1',
  Level_1_5 = 'LEVEL_1_5',
  Level_2 = 'LEVEL_2',
  Level_2_5 = 'LEVEL_2_5',
  Level_3 = 'LEVEL_3',
  Level_3_5 = 'LEVEL_3_5',
  Level_4 = 'LEVEL_4',
  Level_4_5 = 'LEVEL_4_5',
  Level_5 = 'LEVEL_5',
}

export type Location = {
  __typename?: 'Location'
  description: TextContent
  geolocation: GeoPoint
  lastStoryImg?: Maybe<Scalars['String']>
  name: Scalars['String']
  stories?: Maybe<Array<Story>>
}

export type MultipleChoiceAnswerSet = {
  __typename?: 'MultipleChoiceAnswerSet'
  correct: Array<Scalars['Int']>
  kind: Scalars['String']
  options: Array<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  activateCityCoupon: CityCoupon
  activateScourCoupon: Coupon
  addAchievement: Achievement
  addArticle: Article
  addArticleChallenge: Challenge
  addCity: City
  addDiscountCode: DiscountCode
  addOwnedScour: User
  addPartyApprovedLocation: Party
  addPayment: Payment
  addRecentCity: User
  addScour: Scour
  addScourCoupon: Coupon
  addScourStop: Stop
  addStopChallenge: Challenge
  addUserLocation: Array<UserLocation>
  addUserOccasionalLocation: Array<UserOccasionalLocation>
  answerArticleQuiz: User
  answerDailyChallenge?: Maybe<AnswerDailyChallengeMutationResponse>
  changeUserProfilePicture: User
  changeUsersShouldShowInstructions: User
  changeUsersShouldShowTutorial: User
  createParty: Party
  createPartyJoin: PartyJoin
  createUser: User
  deleteAchievement: Scalars['ID']
  deleteArticle: Scalars['ID']
  deleteArticleChallenge: Scalars['ID']
  deleteCity: Scalars['ID']
  deleteOwnedScour: Scalars['ID']
  deletePartyJoin: DeletePartyJoinResponse
  deleteScour: Scalars['ID']
  deleteScourCoupon: Scalars['ID']
  deleteScourStop: Scalars['ID']
  deleteStopChallenge: Scalars['ID']
  deleteUser: Scalars['ID']
  endParty: Party
  endScour: EndScourResponse
  joinParty: Party
  leaveParty: Party
  pauseParty: Party
  pickupPin: User
  rateScour: RateScourResult
  redeemCode?: Maybe<User>
  redeemPin: PinsOrder
  removePartyMembers: Party
  removeUserFromParty: Party
  reportBug?: Maybe<BugReport>
  setPartyAnswers: Party
  setPartyPausedAt: Party
  setShouldNotify: UsersOwnedScour
  startParty: Party
  updateAchievement: Achievement
  updateArticle: Article
  updateArticleChallenge: Challenge
  updateCity: City
  updateCouponScours: Coupon
  updateOwnedScour: UsersOwnedScour
  updateScour: Scour
  updateScourStop: Stop
  updateStopChallenge: Challenge
  updateUserRole: User
}

export type MutationActivateCityCouponArgs = {
  input: ActivateCityCouponInput
}

export type MutationActivateScourCouponArgs = {
  input: ActivateDefaultCouponInput
}

export type MutationAddAchievementArgs = {
  input: AddAchievementInput
}

export type MutationAddArticleArgs = {
  input: AddArticleInput
}

export type MutationAddArticleChallengeArgs = {
  input: AddArticleChallengeInput
}

export type MutationAddCityArgs = {
  input: AddCityInput
}

export type MutationAddDiscountCodeArgs = {
  input: AddDiscountCodeInput
}

export type MutationAddOwnedScourArgs = {
  input: AddOwnedScourInput
}

export type MutationAddPartyApprovedLocationArgs = {
  input: AddPartyApprovedLocationInput
}

export type MutationAddPaymentArgs = {
  input: AddPaymentInput
}

export type MutationAddRecentCityArgs = {
  cityId: Scalars['ID']
}

export type MutationAddScourArgs = {
  input: AddScourInput
}

export type MutationAddScourCouponArgs = {
  input: AddScourCouponInput
}

export type MutationAddScourStopArgs = {
  input: AddScourStopInput
}

export type MutationAddStopChallengeArgs = {
  input: AddStopChallengeInput
}

export type MutationAddUserLocationArgs = {
  input: AddUserLocationInput
}

export type MutationAddUserOccasionalLocationArgs = {
  input: AddUserOccasionalLocationInput
}

export type MutationAnswerArticleQuizArgs = {
  input: UpdateUsersQuizzesInput
}

export type MutationAnswerDailyChallengeArgs = {
  answer: Scalars['String']
  elapsedTime: Scalars['Float']
  usedHint?: InputMaybe<Scalars['Boolean']>
}

export type MutationChangeUserProfilePictureArgs = {
  imageUrl: Scalars['String']
}

export type MutationChangeUsersShouldShowInstructionsArgs = {
  shouldShowInstructions: Scalars['Boolean']
}

export type MutationChangeUsersShouldShowTutorialArgs = {
  shouldShowTutorial: Scalars['Boolean']
}

export type MutationCreatePartyArgs = {
  input: CreatePartyInput
}

export type MutationCreatePartyJoinArgs = {
  partyId: Scalars['ID']
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeleteAchievementArgs = {
  achievementId: Scalars['ID']
}

export type MutationDeleteArticleArgs = {
  articleId: Scalars['ID']
}

export type MutationDeleteArticleChallengeArgs = {
  input: DeleteArticleChallengeInput
}

export type MutationDeleteCityArgs = {
  cityId: Scalars['ID']
}

export type MutationDeleteOwnedScourArgs = {
  ownedScourId: Scalars['ID']
}

export type MutationDeletePartyJoinArgs = {
  partyJoinId: Scalars['ID']
}

export type MutationDeleteScourArgs = {
  scourId: Scalars['ID']
}

export type MutationDeleteScourCouponArgs = {
  couponId: Scalars['ID']
}

export type MutationDeleteScourStopArgs = {
  input: DeleteScourStopInput
}

export type MutationDeleteStopChallengeArgs = {
  input: DeleteStopChallengeInput
}

export type MutationDeleteUserArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationEndPartyArgs = {
  partyId: Scalars['ID']
}

export type MutationEndScourArgs = {
  input: EndScourInput
}

export type MutationJoinPartyArgs = {
  partyId: Scalars['ID']
}

export type MutationLeavePartyArgs = {
  partyId: Scalars['ID']
}

export type MutationPausePartyArgs = {
  input: PausePartyInput
}

export type MutationPickupPinArgs = {
  input: PickupPinInput
}

export type MutationRateScourArgs = {
  input: RateScourInput
}

export type MutationRedeemCodeArgs = {
  code: Scalars['ID']
}

export type MutationRedeemPinArgs = {
  input: RedeemPinInput
}

export type MutationRemovePartyMembersArgs = {
  partyId: Scalars['ID']
}

export type MutationRemoveUserFromPartyArgs = {
  partyId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationReportBugArgs = {
  input: BugReportInput
}

export type MutationSetPartyAnswersArgs = {
  input: SetPartyAnswersInput
}

export type MutationSetPartyPausedAtArgs = {
  input: PausePartyInput
}

export type MutationSetShouldNotifyArgs = {
  input: SetShouldNotifyInput
}

export type MutationStartPartyArgs = {
  partyId: Scalars['ID']
}

export type MutationUpdateAchievementArgs = {
  input: UpdateAchievementInput
}

export type MutationUpdateArticleArgs = {
  input: UpdateArticleInput
}

export type MutationUpdateArticleChallengeArgs = {
  input: UpdateArticleChallengeInput
}

export type MutationUpdateCityArgs = {
  input: UpdateCityInput
}

export type MutationUpdateCouponScoursArgs = {
  input: UpdateCouponScoursInput
}

export type MutationUpdateOwnedScourArgs = {
  input: UpdateOwnedScourInput
}

export type MutationUpdateScourArgs = {
  input: UpdateScourInput
}

export type MutationUpdateScourStopArgs = {
  input: UpdateScourStopInput
}

export type MutationUpdateStopChallengeArgs = {
  input: UpdateStopChallengeInput
}

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput
}

export type NavigationInput = {
  geolocation: GeoPointInput
  image?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type NavigationInputPartial = {
  geolocation?: InputMaybe<GeoPointInput>
  image?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type OwnedScour = {
  __typename?: 'OwnedScour'
  pinsAmount: Scalars['Int']
  redeemed: Scalars['Boolean']
  redeemedCoupon?: Maybe<OwnedScourCoupon>
  redeemedCouponId?: Maybe<Scalars['ID']>
  scour: Scour
  shouldNotify?: Maybe<Scalars['Boolean']>
}

export type OwnedScourCoupon = CityCoupon | Coupon

export type PartialChallengeInput = {
  answerSet?: InputMaybe<AnswerSetInput>
  explanation?: InputMaybe<Scalars['String']>
  hint?: InputMaybe<HintInput>
  image?: InputMaybe<Scalars['String']>
  navigation?: InputMaybe<NavigationInputPartial>
  order?: InputMaybe<Scalars['Int']>
  question?: InputMaybe<Scalars['String']>
}

export type Party = {
  __typename?: 'Party'
  answers: Array<PartyAnswer>
  city: Scalars['String']
  id: Scalars['ID']
  owner?: Maybe<User>
  ownerId: Scalars['ID']
  paused: Scalars['Boolean']
  pausedAt?: Maybe<Scalars['DateTime']>
  pausedAtChallenge?: Maybe<Challenge>
  pausedAtChallengeId?: Maybe<Scalars['ID']>
  pausedAtPage?: Maybe<PausedAtPage>
  pausedAtStop?: Maybe<Stop>
  pausedAtStopId?: Maybe<Scalars['ID']>
  scour: Scour
  started: Scalars['Boolean']
  startedAt: Scalars['DateTime']
  userIds: Array<Scalars['ID']>
  users: Array<PartyUser>
}

export type PartyAnswer = {
  __typename?: 'PartyAnswer'
  answer: StringOrInt
  challenge?: Maybe<ChallengeWithExplanation>
  challengeId: Scalars['ID']
  correct: Scalars['Boolean']
  elapsedTime: Scalars['Float']
  usedHint: Scalars['Boolean']
  user: User
  userId: Scalars['ID']
  xp: Scalars['Int']
}

export type PartyApprovedLocation = {
  __typename?: 'PartyApprovedLocation'
  challengeId: Scalars['ID']
  user?: Maybe<User>
  userId: Scalars['ID']
}

export type PartyById = {
  __typename?: 'PartyById'
  answers: Array<PartyAnswer>
  approvedLocations: Array<PartyApprovedLocation>
  city: Scalars['String']
  id: Scalars['ID']
  owner?: Maybe<User>
  ownerId: Scalars['ID']
  paused: Scalars['Boolean']
  pausedAt?: Maybe<Scalars['DateTime']>
  pausedAtChallenge?: Maybe<Challenge>
  pausedAtChallengeId?: Maybe<Scalars['ID']>
  pausedAtPage?: Maybe<PausedAtPage>
  pausedAtStop?: Maybe<Stop>
  pausedAtStopId?: Maybe<Scalars['ID']>
  scour: Scour
  started: Scalars['Boolean']
  startedAt: Scalars['DateTime']
  userIds: Array<Scalars['ID']>
  users: Array<PartyUser>
}

export type PartyJoin = {
  __typename?: 'PartyJoin'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  party: Party
  partyId: Scalars['ID']
}

export type PartyUser = {
  __typename?: 'PartyUser'
  id: Scalars['ID']
  profilePictureUrl: Scalars['String']
  username: Scalars['String']
}

export type PausePartyInput = {
  challengeId: Scalars['ID']
  partyId: Scalars['ID']
  pausedAtPage: PausedAtPage
  stopId: Scalars['ID']
}

export enum PauseReasonType {
  FoodBreak = 'FOOD_BREAK',
  IssueWithTheApp = 'ISSUE_WITH_THE_APP',
  IWasJustTryingTheApp = 'I_WAS_JUST_TRYING_THE_APP',
  JustTakingABreak = 'JUST_TAKING_A_BREAK',
  NotInterested = 'NOT_INTERESTED',
  Other = 'OTHER',
  TooLong = 'TOO_LONG',
  Weather = 'WEATHER',
}

export enum PausedAtPage {
  Challenge = 'CHALLENGE',
  Map = 'MAP',
  Stop = 'STOP',
  Timeout = 'TIMEOUT',
}

export type Payment = {
  __typename?: 'Payment'
  finished: Scalars['Boolean']
  pinsAmount: Scalars['Int']
  purchaseId: Scalars['String']
  purchaseTime: Scalars['DateTime']
  scour: Scour
  scourId: Scalars['ID']
  user: User
  userId: Scalars['ID']
}

export type PickupPinInput = {
  pinLocationId: Scalars['ID']
  scourId: Scalars['ID']
}

export type Pin = {
  __typename?: 'Pin'
  achievementId?: Maybe<Scalars['ID']>
  amount: Scalars['Int']
  id: Scalars['ID']
  redeemed: Scalars['Boolean']
  userId: Scalars['ID']
}

export type PinData = {
  amount: Scalars['Int']
  id: Scalars['ID']
  redeemed?: InputMaybe<Scalars['Boolean']>
}

export type PinLocation = {
  __typename?: 'PinLocation'
  address: PinLocationAddress
  geolocation: GeoPoint
  id: Scalars['ID']
  name: Scalars['String']
}

export type PinLocationAddress = {
  __typename?: 'PinLocationAddress'
  aptNumber?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type PinsOrder = {
  __typename?: 'PinsOrder'
  address?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  pinsAmount: Scalars['Int']
  scour: Scour
  scourId: Scalars['ID']
  scourName: Scalars['String']
  user: User
  userId: Scalars['ID']
  username: Scalars['String']
}

export type Popup = {
  __typename?: 'Popup'
  description: TextContent
  geofence: GeoFence
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type PopupInput = {
  article: TextContentInput
  geolocation: GeoPointInput
  name?: InputMaybe<Scalars['String']>
  radius: Scalars['Int']
}

export type PreciseGeoPointInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  timestamp: Scalars['DateTime']
}

export type Query = {
  __typename?: 'Query'
  achievement?: Maybe<Achievement>
  achievements: Array<Achievement>
  allParties: Array<AllParty>
  article?: Maybe<Article>
  articles: Array<Article>
  cities: Array<City>
  city?: Maybe<City>
  cityCoupon?: Maybe<CityCoupon>
  closestPinLocations: Array<PinLocation>
  heapMemory: Scalars['String']
  me?: Maybe<User>
  parties: Array<Party>
  party?: Maybe<PartyById>
  partyJoin?: Maybe<PartyJoin>
  pausedParties: Array<Maybe<Party>>
  pins: Array<Pin>
  relevantCities: RelevantCitiesResponse
  scour?: Maybe<Scour>
  scourCoupon?: Maybe<Coupon>
  scourCoupons: Array<Coupon>
  scours: Array<Scour>
  suggestedCities: SuggestedCitiesResponse
  todaysDailyChallenge?: Maybe<TodaysDailyChallenge>
  userEmailByUsername?: Maybe<UserEmailByUsernameResponse>
  userLocations: Array<UserLocation>
  users: Array<User>
  usersOwnedScours: Array<UsersOwnedScour>
  usersPins: Array<Pin>
}

export type QueryAchievementArgs = {
  achievementId: Scalars['ID']
}

export type QueryArticleArgs = {
  articleId: Scalars['ID']
}

export type QueryCitiesArgs = {
  includeHidden?: InputMaybe<Scalars['Boolean']>
}

export type QueryCityArgs = {
  cityId: Scalars['ID']
}

export type QueryCityCouponArgs = {
  couponId: Scalars['ID']
}

export type QueryClosestPinLocationsArgs = {
  input: GeoPointInput
}

export type QueryPartyArgs = {
  partyId: Scalars['ID']
}

export type QueryPartyJoinArgs = {
  partyJoinId: Scalars['ID']
}

export type QueryRelevantCitiesArgs = {
  input: GeoPointInput
}

export type QueryScourArgs = {
  scourId: Scalars['ID']
}

export type QueryScourCouponArgs = {
  couponId: Scalars['ID']
}

export type QueryScoursArgs = {
  includeHidden?: InputMaybe<Scalars['Boolean']>
}

export type QuerySuggestedCitiesArgs = {
  input: GeoPointInput
}

export type QueryUserEmailByUsernameArgs = {
  username: Scalars['String']
}

export type QueryUsersOwnedScoursArgs = {
  userId: Scalars['ID']
}

export type QuizAnswer = {
  __typename?: 'QuizAnswer'
  answer: Scalars['String']
  challengeId: Scalars['String']
  /** @deprecated challengeIdx is deprecated. Please use challengeId instead. */
  challengeIdx: Scalars['String']
  correct: Scalars['Boolean']
}

export type QuizAnswerInput = {
  answer: Scalars['String']
  challengeId: Scalars['String']
  correct: Scalars['Boolean']
}

export type RateScourInput = {
  customReason?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  intendedToContinue?: InputMaybe<Scalars['Boolean']>
  rating: Scalars['Int']
  reason?: InputMaybe<PauseReasonType>
  scourId: Scalars['ID']
}

export type RateScourResult = {
  __typename?: 'RateScourResult'
  createdAt: Scalars['DateTime']
  customReason?: Maybe<Scalars['String']>
  description: Scalars['String']
  intendedToContinue?: Maybe<Scalars['Boolean']>
  rating: Scalars['Int']
  reason?: Maybe<PauseReasonType>
  scour: Scour
  scourId: Scalars['ID']
  user: User
}

export type RecentCity = {
  __typename?: 'RecentCity'
  city: City
  time: Scalars['DateTime']
}

export type RedeemPinInput = {
  address: AddressInput
  scourId: Scalars['ID']
}

export type RelevantCitiesResponse = {
  __typename?: 'RelevantCitiesResponse'
  cities: Array<City>
  inCity: Scalars['Boolean']
}

export enum Role {
  Admin = 'admin',
  ContentPartner = 'content_partner',
  Internal = 'internal',
}

export type Scour = {
  __typename?: 'Scour'
  additionalInfo?: Maybe<Scalars['String']>
  beta: Scalars['Boolean']
  description: TextContent
  discounted?: Maybe<Scalars['Boolean']>
  distance?: Maybe<Scalars['Float']>
  duration: Scalars['Int']
  free?: Maybe<Scalars['Boolean']>
  hidden: Scalars['Boolean']
  id: Scalars['ID']
  leaderboard: Array<LeaderboardRow>
  level?: Maybe<Level>
  name: Scalars['String']
  popups: Array<Popup>
  stops: Array<Stop>
  tags: Array<Scalars['String']>
}

export type ScourCouponUsageInfo = {
  __typename?: 'ScourCouponUsageInfo'
  couponId: Scalars['ID']
  scours: Array<Scour>
  usedAt: Scalars['DateTime']
  user: User
}

export type SetPartyAnswersInput = {
  answer: Scalars['String']
  challengeId: Scalars['ID']
  correct: Scalars['Boolean']
  elapsedTime: Scalars['Float']
  partyId: Scalars['ID']
  usedHint?: InputMaybe<Scalars['Boolean']>
  xp: Scalars['Int']
}

export type Stop = {
  __typename?: 'Stop'
  challenges: Array<Challenge>
  id: Scalars['ID']
  location: Location
  navigationInfo?: Maybe<Scalars['String']>
  order: Scalars['Int']
}

export type StopInput = {
  challenges: Array<ChallengeInput>
  location: CityLocationInput
  navigationInfo?: InputMaybe<Scalars['String']>
}

export type Story = {
  __typename?: 'Story'
  image: Scalars['String']
  order: Scalars['Int']
  text: Scalars['String']
}

export type StoryInput = {
  image: Scalars['String']
  order: Scalars['Int']
  text: Scalars['String']
}

export type StringBox = {
  __typename?: 'StringBox'
  value: Scalars['String']
}

export type StringOrInt = IntBox | StringBox

export type Subscription = {
  __typename?: 'Subscription'
  partyTimeout?: Maybe<Scalars['String']>
}

export type SuggestedCitiesResponse = {
  __typename?: 'SuggestedCitiesResponse'
  nearby: Array<City>
  recent: Array<City>
}

export type TextContent = {
  __typename?: 'TextContent'
  imageUrls: Array<Scalars['String']>
  text: Scalars['String']
}

export type TextContentInput = {
  images: Array<Scalars['String']>
  text: Scalars['String']
}

export type Tile = Article | Scour

export type TodaysDailyChallenge = {
  __typename?: 'TodaysDailyChallenge'
  dailyChallenge: DailyChallengeWithoutExplanation
  myAnswer?: Maybe<DailyChallengeCompleted>
  myDailyChallengesInfo?: Maybe<DailyChallengesInfo>
}

export type UpdateAchievementInput = {
  achievementId: Scalars['ID']
  description?: InputMaybe<Scalars['String']>
  icon?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  requiredScourIds?: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateArticleChallengeInput = {
  articleId: Scalars['ID']
  challenge: PartialChallengeInput
  challengeId: Scalars['ID']
}

export type UpdateArticleInput = {
  articleId: Scalars['ID']
  body?: InputMaybe<Scalars['String']>
  hidden?: InputMaybe<Scalars['Boolean']>
  images?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
  summary?: InputMaybe<Array<Scalars['String']>>
}

export type UpdateCityInput = {
  articleIds?: InputMaybe<Array<Scalars['ID']>>
  cityId: Scalars['ID']
  geoPoint?: InputMaybe<GeoPointInput>
  hidden?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  radius?: InputMaybe<Scalars['Int']>
  scourIds?: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateCouponScoursInput = {
  couponId: Scalars['ID']
  scourIds: Array<Scalars['ID']>
}

export type UpdateScourInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  beta?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<TextContentInput>
  discounted?: InputMaybe<Scalars['Boolean']>
  distance?: InputMaybe<Scalars['Float']>
  duration?: InputMaybe<Scalars['Int']>
  free?: InputMaybe<Scalars['Boolean']>
  hidden?: InputMaybe<Scalars['Boolean']>
  level?: InputMaybe<Level>
  name?: InputMaybe<Scalars['String']>
  popups?: InputMaybe<Array<PopupInput>>
  scourId: Scalars['String']
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type UpdateScourStopInput = {
  location?: InputMaybe<CityLocationInput>
  navigationInfo?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  scourId: Scalars['ID']
  stopId: Scalars['ID']
}

export type UpdateStopChallengeInput = {
  challenge: PartialChallengeInput
  challengeId: Scalars['ID']
  stopId: Scalars['ID']
}

export type UpdateUserRoleInput = {
  roles: Array<Role>
  userId: Scalars['ID']
}

export type UpdateUsersQuizzesInput = {
  articleId: Scalars['ID']
  elapsedTime: Scalars['Float']
  quizAnswer: QuizAnswerInput
}

export type User = {
  __typename?: 'User'
  achievementIds: Array<Scalars['ID']>
  achievements: Array<Achievement>
  address?: Maybe<Address>
  completedScourIds: Array<Scalars['ID']>
  completedScours: Array<Scour>
  dailyChallenges?: Maybe<DailyChallengesInfo>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  level: Scalars['Int']
  ownedScours?: Maybe<Array<OwnedScour>>
  profilePictureUrl: Scalars['String']
  quizzes?: Maybe<Array<ArticleQuiz>>
  recentCities: Array<RecentCity>
  roles: Array<Role>
  sendEmails: Scalars['Boolean']
  shouldShowInstructions: Scalars['Boolean']
  shouldShowTutorial?: Maybe<Scalars['Boolean']>
  totalPoints: Scalars['Int']
  userLocations?: Maybe<Array<UserLocation>>
  userOccasionalLocations?: Maybe<Array<UserOccasionalLocation>>
  username: Scalars['String']
}

export type UserEmailByUsernameResponse = {
  __typename?: 'UserEmailByUsernameResponse'
  email?: Maybe<Scalars['String']>
}

export type UserLocation = {
  __typename?: 'UserLocation'
  id: Scalars['ID']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  partyId: Scalars['ID']
  timestamp: Scalars['DateTime']
  userId: Scalars['ID']
}

export type UserOccasionalLocation = {
  __typename?: 'UserOccasionalLocation'
  id: Scalars['ID']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  timestamp: Scalars['DateTime']
  userId: Scalars['ID']
}

export type UsersOwnedScour = {
  __typename?: 'UsersOwnedScour'
  cityCouponId?: Maybe<Scalars['ID']>
  couponType?: Maybe<CouponType>
  createdAt?: Maybe<Scalars['DateTime']>
  emailSent?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  pinsAmount: Scalars['Int']
  redeemed: Scalars['Boolean']
  scourCouponId?: Maybe<Scalars['ID']>
  scourId: Scalars['ID']
  shouldNotify?: Maybe<Scalars['Boolean']>
  userId: Scalars['ID']
}

export type AddDiscountCodeInput = {
  discountCode: Scalars['ID']
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

export type AddOwnedScourInput = {
  couponId?: InputMaybe<Scalars['ID']>
  couponType?: InputMaybe<CouponType>
  pinsAmount: Scalars['Int']
  scourId: Scalars['ID']
  userId: Scalars['ID']
}

export type SetShouldNotifyInput = {
  scourId: Scalars['ID']
  shouldNotify: Scalars['Boolean']
}

export type UpdateOwnedScourInput = {
  couponId?: InputMaybe<Scalars['ID']>
  couponType?: InputMaybe<CouponType>
  ownedScourId: Scalars['ID']
  pinsAmount: Scalars['Int']
  pinsData?: InputMaybe<Array<PinData>>
  redeemed: Scalars['Boolean']
  scourId: Scalars['ID']
}

export type ActivateCityCouponMutationVariables = Exact<{
  input: ActivateCityCouponInput
}>

export type ActivateCityCouponMutation = {
  __typename?: 'Mutation'
  activateCityCoupon: { __typename?: 'CityCoupon'; id: string }
}

export type ActivateScourCouponMutationVariables = Exact<{
  input: ActivateDefaultCouponInput
}>

export type ActivateScourCouponMutation = {
  __typename?: 'Mutation'
  activateScourCoupon: { __typename?: 'Coupon'; id: string }
}

export type AddPartyApprovedLocationMutationVariables = Exact<{
  input: AddPartyApprovedLocationInput
}>

export type AddPartyApprovedLocationMutation = {
  __typename?: 'Mutation'
  addPartyApprovedLocation: { __typename?: 'Party'; id: string }
}

export type AddPaymentMutationVariables = Exact<{
  input: AddPaymentInput
}>

export type AddPaymentMutation = {
  __typename?: 'Mutation'
  addPayment: {
    __typename?: 'Payment'
    user: { __typename?: 'User'; id: string }
  }
}

export type AddRecentCityMutationVariables = Exact<{
  cityId: Scalars['ID']
}>

export type AddRecentCityMutation = {
  __typename?: 'Mutation'
  addRecentCity: { __typename?: 'User'; id: string }
}

export type AddUserLocationMutationVariables = Exact<{
  input: AddUserLocationInput
}>

export type AddUserLocationMutation = {
  __typename?: 'Mutation'
  addUserLocation: Array<{ __typename?: 'UserLocation'; id: string }>
}

export type AddUserOccasionalLocationMutationVariables = Exact<{
  input: AddUserOccasionalLocationInput
}>

export type AddUserOccasionalLocationMutation = {
  __typename?: 'Mutation'
  addUserOccasionalLocation: Array<{
    __typename?: 'UserOccasionalLocation'
    id: string
  }>
}

export type AnswerArticleQuizMutationVariables = Exact<{
  input: UpdateUsersQuizzesInput
}>

export type AnswerArticleQuizMutation = {
  __typename?: 'Mutation'
  answerArticleQuiz: { __typename?: 'User'; totalPoints: number }
}

export type AnswerDailyChallengeMutationVariables = Exact<{
  elapsedTime: Scalars['Float']
  answer: Scalars['String']
  usedHint?: InputMaybe<Scalars['Boolean']>
}>

export type AnswerDailyChallengeMutation = {
  __typename?: 'Mutation'
  answerDailyChallenge?: {
    __typename?: 'AnswerDailyChallengeMutationResponse'
    myAnswer: {
      __typename?: 'DailyChallengeCompleted'
      xp: number
      correct: boolean
    }
    dailyChallenge: {
      __typename?: 'DailyChallengeWithExplanation'
      challenge: {
        __typename?: 'ChallengeWithExplanation'
        explanation?: string | null
      }
    }
  } | null
}

export type ChangeUserProfilePictureMutationVariables = Exact<{
  imageUrl: Scalars['String']
}>

export type ChangeUserProfilePictureMutation = {
  __typename?: 'Mutation'
  changeUserProfilePicture: { __typename?: 'User'; id: string }
}

export type ChangeUsersShouldShowInstructionsMutationVariables = Exact<{
  shouldShowInstructions: Scalars['Boolean']
}>

export type ChangeUsersShouldShowInstructionsMutation = {
  __typename?: 'Mutation'
  changeUsersShouldShowInstructions: { __typename?: 'User'; id: string }
}

export type ChangeUsersShouldShowTutorialMutationVariables = Exact<{
  shouldShowTutorial: Scalars['Boolean']
}>

export type ChangeUsersShouldShowTutorialMutation = {
  __typename?: 'Mutation'
  changeUsersShouldShowTutorial: { __typename?: 'User'; id: string }
}

export type CreatePartyJoinMutationVariables = Exact<{
  partyId: Scalars['ID']
}>

export type CreatePartyJoinMutation = {
  __typename?: 'Mutation'
  createPartyJoin: { __typename?: 'PartyJoin'; id: string }
}

export type CreatePartyMutationVariables = Exact<{
  input: CreatePartyInput
}>

export type CreatePartyMutation = {
  __typename?: 'Mutation'
  createParty: { __typename?: 'Party'; id: string }
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: { __typename?: 'User'; id: string }
}

export type DeletePartyJoinMutationVariables = Exact<{
  partyJoinId: Scalars['ID']
}>

export type DeletePartyJoinMutation = {
  __typename?: 'Mutation'
  deletePartyJoin: { __typename?: 'DeletePartyJoinResponse'; id: string }
}

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser: string }

export type EndPartyMutationVariables = Exact<{
  partyId: Scalars['ID']
}>

export type EndPartyMutation = {
  __typename?: 'Mutation'
  endParty: { __typename?: 'Party'; id: string }
}

export type EndScourMutationVariables = Exact<{
  input: EndScourInput
}>

export type EndScourMutation = {
  __typename?: 'Mutation'
  endScour: {
    __typename?: 'EndScourResponse'
    user: { __typename?: 'User'; id: string }
  }
}

export type JoinPartyMutationVariables = Exact<{
  partyId: Scalars['ID']
}>

export type JoinPartyMutation = {
  __typename?: 'Mutation'
  joinParty: { __typename?: 'Party'; id: string }
}

export type LeavePartyMutationVariables = Exact<{
  partyId: Scalars['ID']
}>

export type LeavePartyMutation = {
  __typename?: 'Mutation'
  leaveParty: { __typename?: 'Party'; id: string }
}

export type PausePartyMutationVariables = Exact<{
  input: PausePartyInput
}>

export type PausePartyMutation = {
  __typename?: 'Mutation'
  pauseParty: { __typename?: 'Party'; id: string }
}

export type PickupPinMutationVariables = Exact<{
  input: PickupPinInput
}>

export type PickupPinMutation = {
  __typename?: 'Mutation'
  pickupPin: { __typename?: 'User'; id: string }
}

export type RateScourMutationVariables = Exact<{
  input: RateScourInput
}>

export type RateScourMutation = {
  __typename?: 'Mutation'
  rateScour: {
    __typename?: 'RateScourResult'
    user: { __typename?: 'User'; id: string }
  }
}

export type RedeemCodeMutationVariables = Exact<{
  code: Scalars['ID']
}>

export type RedeemCodeMutation = {
  __typename?: 'Mutation'
  redeemCode?: { __typename?: 'User'; id: string } | null
}

export type RedeemPinMutationVariables = Exact<{
  input: RedeemPinInput
}>

export type RedeemPinMutation = {
  __typename?: 'Mutation'
  redeemPin: {
    __typename?: 'PinsOrder'
    user: { __typename?: 'User'; id: string }
  }
}

export type RemovePartyMembersMutationVariables = Exact<{
  partyId: Scalars['ID']
}>

export type RemovePartyMembersMutation = {
  __typename?: 'Mutation'
  removePartyMembers: { __typename?: 'Party'; id: string }
}

export type RemoveUserFromPartyMutationVariables = Exact<{
  partyId: Scalars['ID']
  userId: Scalars['ID']
}>

export type RemoveUserFromPartyMutation = {
  __typename?: 'Mutation'
  removeUserFromParty: { __typename?: 'Party'; id: string }
}

export type ReportBugMutationVariables = Exact<{
  input: BugReportInput
}>

export type ReportBugMutation = {
  __typename?: 'Mutation'
  reportBug?: {
    __typename?: 'BugReport'
    user: { __typename?: 'User'; id: string }
  } | null
}

export type SetPartyAnswersMutationVariables = Exact<{
  input: SetPartyAnswersInput
}>

export type SetPartyAnswersMutation = {
  __typename?: 'Mutation'
  setPartyAnswers: {
    __typename?: 'Party'
    id: string
    answers: Array<{
      __typename?: 'PartyAnswer'
      challenge?: {
        __typename?: 'ChallengeWithExplanation'
        id: string
        explanation?: string | null
      } | null
    }>
  }
}

export type SetPartyPausedAtMutationVariables = Exact<{
  input: PausePartyInput
}>

export type SetPartyPausedAtMutation = {
  __typename?: 'Mutation'
  setPartyPausedAt: { __typename?: 'Party'; id: string }
}

export type SetShouldNotifyMutationVariables = Exact<{
  input: SetShouldNotifyInput
}>

export type SetShouldNotifyMutation = {
  __typename?: 'Mutation'
  setShouldNotify: { __typename?: 'UsersOwnedScour'; id: string }
}

export type StartPartyMutationVariables = Exact<{
  partyId: Scalars['ID']
}>

export type StartPartyMutation = {
  __typename?: 'Mutation'
  startParty: { __typename?: 'Party'; id: string }
}

export type AchievementsQueryVariables = Exact<{ [key: string]: never }>

export type AchievementsQuery = {
  __typename?: 'Query'
  achievements: Array<{
    __typename?: 'Achievement'
    id: string
    description: string
    iconUrl: string
    name: string
    requiredScours: Array<{ __typename?: 'Scour'; id: string }>
  }>
}

export type ArticleQueryVariables = Exact<{
  articleId: Scalars['ID']
}>

export type ArticleQuery = {
  __typename?: 'Query'
  article?: {
    __typename?: 'Article'
    id: string
    name: string
    imageUrls: Array<string>
    body: string
    summary?: Array<string> | null
    hidden: boolean
    challenges?: Array<{
      __typename?: 'Challenge'
      id: string
      question: string
      imageUrl?: string | null
      answerSet:
        | {
            __typename?: 'FreeNumberAnswerSet'
            kind: string
            numberCorrect: Array<number>
          }
        | {
            __typename?: 'FreeTextAnswerSet'
            kind: string
            textCorrect: Array<string>
          }
        | {
            __typename?: 'MultipleChoiceAnswerSet'
            options: Array<string>
            kind: string
            multipleChoiceCorrect: Array<number>
          }
      navigation?: {
        __typename?: 'ChallengeNavigation'
        name: string
        imageUrl?: string | null
        geolocation: {
          __typename?: 'GeoPoint'
          longitude: number
          latitude: number
        }
      } | null
      hint?: {
        __typename?: 'Hint'
        text: string
        imageUrl?: string | null
      } | null
    }> | null
  } | null
}

export type CitiesQueryVariables = Exact<{ [key: string]: never }>

export type CitiesQuery = {
  __typename?: 'Query'
  cities: Array<{
    __typename?: 'City'
    id: string
    name: string
    tiles: Array<
      | {
          __typename?: 'Article'
          name: string
          id: string
          imageUrls: Array<string>
          hidden: boolean
        }
      | {
          __typename?: 'Scour'
          name: string
          id: string
          hidden: boolean
          duration: number
          discounted?: boolean | null
          level?: Level | null
          description: {
            __typename?: 'TextContent'
            text: string
            imageUrls: Array<string>
          }
          stops: Array<{
            __typename?: 'Stop'
            location: {
              __typename?: 'Location'
              geolocation: {
                __typename?: 'GeoPoint'
                longitude: number
                latitude: number
              }
            }
          }>
        }
    >
    geofence: {
      __typename?: 'GeoFence'
      radius: number
      geolocation: {
        __typename?: 'GeoPoint'
        longitude: number
        latitude: number
      }
    }
  }>
}

export type CityCouponQueryVariables = Exact<{
  couponId: Scalars['ID']
}>

export type CityCouponQuery = {
  __typename?: 'Query'
  cityCoupon?: {
    __typename?: 'CityCoupon'
    id: string
    city: {
      __typename?: 'City'
      id: string
      name: string
      geofence: {
        __typename?: 'GeoFence'
        radius: number
        geolocation: {
          __typename?: 'GeoPoint'
          latitude: number
          longitude: number
        }
      }
      tiles: Array<
        | {
            __typename?: 'Article'
            id: string
            name: string
            imageUrls: Array<string>
            hidden: boolean
          }
        | {
            __typename?: 'Scour'
            id: string
            name: string
            hidden: boolean
            duration: number
            discounted?: boolean | null
            level?: Level | null
            description: {
              __typename?: 'TextContent'
              text: string
              imageUrls: Array<string>
            }
            stops: Array<{
              __typename?: 'Stop'
              location: {
                __typename?: 'Location'
                geolocation: {
                  __typename?: 'GeoPoint'
                  longitude: number
                  latitude: number
                }
              }
            }>
          }
      >
    }
    usageInfo: Array<{
      __typename?: 'CityCouponUsageInfo'
      user: { __typename?: 'User'; id: string }
    }>
  } | null
}

export type ClosestPinLocationsQueryVariables = Exact<{
  input: GeoPointInput
}>

export type ClosestPinLocationsQuery = {
  __typename?: 'Query'
  closestPinLocations: Array<{
    __typename?: 'PinLocation'
    id: string
    name: string
    geolocation: {
      __typename?: 'GeoPoint'
      latitude: number
      longitude: number
    }
    address: {
      __typename?: 'PinLocationAddress'
      zipCode?: string | null
      state?: string | null
      city?: string | null
      aptNumber?: string | null
      street?: string | null
    }
  }>
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    username: string
    profilePictureUrl: string
    shouldShowInstructions: boolean
    shouldShowTutorial?: boolean | null
    totalPoints: number
    sendEmails: boolean
    email?: string | null
    roles: Array<Role>
    completedScours: Array<{
      __typename?: 'Scour'
      id: string
      free?: boolean | null
      distance?: number | null
      name: string
      description: { __typename?: 'TextContent'; imageUrls: Array<string> }
    }>
    achievements: Array<{ __typename?: 'Achievement'; id: string }>
    recentCities: Array<{
      __typename?: 'RecentCity'
      time: any
      city: { __typename?: 'City'; id: string; name: string }
    }>
    address?: {
      __typename?: 'Address'
      street?: string | null
      aptNumber?: string | null
      city?: string | null
      state?: string | null
      zipCode?: string | null
      firstName?: string | null
      lastName?: string | null
    } | null
    dailyChallenges?: {
      __typename?: 'DailyChallengesInfo'
      played: number
      currentStreak: number
      maxStreak: number
      correct: number
    } | null
    quizzes?: Array<{
      __typename?: 'ArticleQuiz'
      article: { __typename?: 'Article'; id: string }
      answers?: Array<{
        __typename?: 'QuizAnswer'
        answer: string
        correct: boolean
        challengeId: string
      } | null> | null
    }> | null
    ownedScours?: Array<{
      __typename?: 'OwnedScour'
      pinsAmount: number
      redeemed: boolean
      redeemedCouponId?: string | null
      shouldNotify?: boolean | null
      scour: { __typename?: 'Scour'; id: string; name: string }
    }> | null
  } | null
}

export type PartiesQueryVariables = Exact<{ [key: string]: never }>

export type PartiesQuery = {
  __typename?: 'Query'
  parties: Array<{
    __typename?: 'Party'
    id: string
    started: boolean
    startedAt: any
    city: string
    paused: boolean
    pausedAt?: any | null
    pausedAtStopId?: string | null
    pausedAtChallengeId?: string | null
    pausedAtPage?: PausedAtPage | null
    ownerId: string
    users: Array<{ __typename?: 'PartyUser'; id: string }>
    scour: {
      __typename?: 'Scour'
      id: string
      stops: Array<{ __typename?: 'Stop'; id: string }>
    }
    answers: Array<{
      __typename?: 'PartyAnswer'
      xp: number
      elapsedTime: number
      correct: boolean
      user: {
        __typename?: 'User'
        id: string
        username: string
        profilePictureUrl: string
      }
    }>
  }>
}

export type PartyJoinQueryVariables = Exact<{
  partyJoinId: Scalars['ID']
}>

export type PartyJoinQuery = {
  __typename?: 'Query'
  partyJoin?: {
    __typename?: 'PartyJoin'
    createdAt?: any | null
    party: { __typename?: 'Party'; id: string }
  } | null
}

export type PartyQueryVariables = Exact<{
  partyId: Scalars['ID']
}>

export type PartyQuery = {
  __typename?: 'Query'
  party?: {
    __typename?: 'PartyById'
    id: string
    started: boolean
    startedAt: any
    city: string
    paused: boolean
    pausedAt?: any | null
    pausedAtStopId?: string | null
    pausedAtChallengeId?: string | null
    pausedAtPage?: PausedAtPage | null
    ownerId: string
    users: Array<{
      __typename?: 'PartyUser'
      id: string
      username: string
      profilePictureUrl: string
    }>
    scour: {
      __typename?: 'Scour'
      id: string
      name: string
      stops: Array<{
        __typename?: 'Stop'
        id: string
        location: { __typename?: 'Location'; name: string }
        challenges: Array<{ __typename?: 'Challenge'; id: string }>
      }>
    }
    approvedLocations: Array<{
      __typename?: 'PartyApprovedLocation'
      challengeId: string
      userId: string
    }>
    answers: Array<{
      __typename?: 'PartyAnswer'
      xp: number
      usedHint: boolean
      correct: boolean
      elapsedTime: number
      challengeId: string
      user: { __typename?: 'User'; id: string }
      answer:
        | { __typename?: 'IntBox'; intAnswer: number }
        | { __typename?: 'StringBox'; stringAnswer: string }
      challenge?: {
        __typename?: 'ChallengeWithExplanation'
        explanation?: string | null
      } | null
    }>
  } | null
}

export type PinsQueryVariables = Exact<{ [key: string]: never }>

export type PinsQuery = {
  __typename?: 'Query'
  pins: Array<{
    __typename?: 'Pin'
    id: string
    amount: number
    redeemed: boolean
    userId: string
    achievementId?: string | null
  }>
}

export type RelevantCitiesQueryVariables = Exact<{
  input: GeoPointInput
}>

export type RelevantCitiesQuery = {
  __typename?: 'Query'
  relevantCities: {
    __typename?: 'RelevantCitiesResponse'
    inCity: boolean
    cities: Array<{
      __typename?: 'City'
      id: string
      name: string
      geofence: {
        __typename?: 'GeoFence'
        radius: number
        geolocation: {
          __typename?: 'GeoPoint'
          latitude: number
          longitude: number
        }
      }
      tiles: Array<
        | {
            __typename?: 'Article'
            id: string
            name: string
            imageUrls: Array<string>
            hidden: boolean
          }
        | {
            __typename?: 'Scour'
            id: string
            name: string
            hidden: boolean
            duration: number
            discounted?: boolean | null
            level?: Level | null
            description: {
              __typename?: 'TextContent'
              text: string
              imageUrls: Array<string>
            }
            stops: Array<{
              __typename?: 'Stop'
              location: {
                __typename?: 'Location'
                geolocation: {
                  __typename?: 'GeoPoint'
                  longitude: number
                  latitude: number
                }
              }
            }>
          }
      >
    }>
  }
}

export type ScourCouponQueryVariables = Exact<{
  couponId: Scalars['ID']
}>

export type ScourCouponQuery = {
  __typename?: 'Query'
  scourCoupon?: {
    __typename?: 'Coupon'
    id: string
    scours: Array<{ __typename?: 'Scour'; id: string; name: string }>
    usageInfo?: Array<{
      __typename?: 'ScourCouponUsageInfo'
      user: { __typename?: 'User'; id: string }
    }> | null
  } | null
}

export type ScourQueryVariables = Exact<{
  scourId: Scalars['ID']
}>

export type ScourQuery = {
  __typename?: 'Query'
  scour?: {
    __typename?: 'Scour'
    name: string
    duration: number
    level?: Level | null
    id: string
    additionalInfo?: string | null
    tags: Array<string>
    beta: boolean
    free?: boolean | null
    discounted?: boolean | null
    hidden: boolean
    leaderboard: Array<{
      __typename?: 'LeaderboardRow'
      points: number
      user: {
        __typename?: 'User'
        id: string
        username: string
        email?: string | null
      }
    }>
    popups: Array<{
      __typename?: 'Popup'
      id: string
      name?: string | null
      description: {
        __typename?: 'TextContent'
        text: string
        imageUrls: Array<string>
      }
      geofence: {
        __typename?: 'GeoFence'
        radius: number
        geolocation: {
          __typename?: 'GeoPoint'
          longitude: number
          latitude: number
        }
      }
    }>
    description: {
      __typename?: 'TextContent'
      imageUrls: Array<string>
      text: string
    }
    stops: Array<{
      __typename?: 'Stop'
      id: string
      navigationInfo?: string | null
      order: number
      location: {
        __typename?: 'Location'
        name: string
        lastStoryImg?: string | null
        description: {
          __typename?: 'TextContent'
          text: string
          imageUrls: Array<string>
        }
        geolocation: {
          __typename?: 'GeoPoint'
          longitude: number
          latitude: number
        }
        stories?: Array<{
          __typename?: 'Story'
          image: string
          order: number
          text: string
        }> | null
      }
      challenges: Array<{
        __typename?: 'Challenge'
        id: string
        question: string
        imageUrl?: string | null
        order: number
        answerSet:
          | {
              __typename?: 'FreeNumberAnswerSet'
              kind: string
              numberCorrect: Array<number>
            }
          | {
              __typename?: 'FreeTextAnswerSet'
              kind: string
              textCorrect: Array<string>
            }
          | {
              __typename?: 'MultipleChoiceAnswerSet'
              options: Array<string>
              kind: string
              multipleChoiceCorrect: Array<number>
            }
        navigation?: {
          __typename?: 'ChallengeNavigation'
          name: string
          imageUrl?: string | null
          geolocation: {
            __typename?: 'GeoPoint'
            longitude: number
            latitude: number
          }
        } | null
        hint?: {
          __typename?: 'Hint'
          text: string
          imageUrl?: string | null
        } | null
      }>
    }>
  } | null
}

export type ScoursQueryVariables = Exact<{ [key: string]: never }>

export type ScoursQuery = {
  __typename?: 'Query'
  scours: Array<{
    __typename?: 'Scour'
    name: string
    id: string
    stops: Array<{
      __typename?: 'Stop'
      location: {
        __typename?: 'Location'
        name: string
        geolocation: {
          __typename?: 'GeoPoint'
          longitude: number
          latitude: number
        }
      }
    }>
  }>
}

export type SuggestedCitiesQueryVariables = Exact<{
  input: GeoPointInput
}>

export type SuggestedCitiesQuery = {
  __typename?: 'Query'
  suggestedCities: {
    __typename?: 'SuggestedCitiesResponse'
    nearby: Array<{
      __typename?: 'City'
      id: string
      name: string
      geofence: {
        __typename?: 'GeoFence'
        radius: number
        geolocation: {
          __typename?: 'GeoPoint'
          longitude: number
          latitude: number
        }
      }
      tiles: Array<
        | {
            __typename?: 'Article'
            id: string
            name: string
            imageUrls: Array<string>
            hidden: boolean
          }
        | {
            __typename?: 'Scour'
            id: string
            name: string
            hidden: boolean
            duration: number
            discounted?: boolean | null
            level?: Level | null
            description: {
              __typename?: 'TextContent'
              text: string
              imageUrls: Array<string>
            }
            stops: Array<{
              __typename?: 'Stop'
              location: {
                __typename?: 'Location'
                geolocation: {
                  __typename?: 'GeoPoint'
                  longitude: number
                  latitude: number
                }
              }
            }>
          }
      >
    }>
    recent: Array<{
      __typename?: 'City'
      id: string
      name: string
      tiles: Array<
        | {
            __typename?: 'Article'
            id: string
            name: string
            imageUrls: Array<string>
            hidden: boolean
          }
        | {
            __typename?: 'Scour'
            id: string
            name: string
            hidden: boolean
            duration: number
            discounted?: boolean | null
            level?: Level | null
            description: {
              __typename?: 'TextContent'
              text: string
              imageUrls: Array<string>
            }
            stops: Array<{
              __typename?: 'Stop'
              location: {
                __typename?: 'Location'
                geolocation: {
                  __typename?: 'GeoPoint'
                  longitude: number
                  latitude: number
                }
              }
            }>
          }
      >
      geofence: {
        __typename?: 'GeoFence'
        radius: number
        geolocation: {
          __typename?: 'GeoPoint'
          longitude: number
          latitude: number
        }
      }
    }>
  }
}

export type TodaysDailyChallengeQueryVariables = Exact<{ [key: string]: never }>

export type TodaysDailyChallengeQuery = {
  __typename?: 'Query'
  todaysDailyChallenge?: {
    __typename?: 'TodaysDailyChallenge'
    dailyChallenge: {
      __typename?: 'DailyChallengeWithoutExplanation'
      date: any
      challenge: {
        __typename?: 'Challenge'
        question: string
        imageUrl?: string | null
        id: string
        order: number
        answerSet:
          | {
              __typename?: 'FreeNumberAnswerSet'
              kind: string
              numberCorrect: Array<number>
            }
          | {
              __typename?: 'FreeTextAnswerSet'
              kind: string
              textCorrect: Array<string>
            }
          | {
              __typename?: 'MultipleChoiceAnswerSet'
              options: Array<string>
              kind: string
              multipleChoiceCorrect: Array<number>
            }
        navigation?: {
          __typename?: 'ChallengeNavigation'
          name: string
          imageUrl?: string | null
          geolocation: {
            __typename?: 'GeoPoint'
            longitude: number
            latitude: number
          }
        } | null
        hint?: {
          __typename?: 'Hint'
          text: string
          imageUrl?: string | null
        } | null
      }
      usersCompleted: Array<{
        __typename?: 'DailyChallengeCompleted'
        correct: boolean
        answer:
          | { __typename?: 'IntBox'; intAnswer: number }
          | { __typename?: 'StringBox'; stringAnswer: string }
      }>
    }
    myAnswer?: {
      __typename?: 'DailyChallengeCompleted'
      correct: boolean
      xp: number
      answer:
        | { __typename?: 'IntBox'; numberAnswer: number }
        | { __typename?: 'StringBox'; stringAnswer: string }
    } | null
    myDailyChallengesInfo?: {
      __typename?: 'DailyChallengesInfo'
      played: number
      currentStreak: number
      correct: number
      maxStreak: number
    } | null
  } | null
}

export type UserEmailByUsernameQueryVariables = Exact<{
  username: Scalars['String']
}>

export type UserEmailByUsernameQuery = {
  __typename?: 'Query'
  userEmailByUsername?: {
    __typename?: 'UserEmailByUsernameResponse'
    email?: string | null
  } | null
}

export const ActivateCityCouponDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateCityCoupon' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivateCityCouponInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateCityCoupon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateCityCouponMutation,
  ActivateCityCouponMutationVariables
>
export const ActivateScourCouponDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateScourCoupon' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivateDefaultCouponInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateScourCoupon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateScourCouponMutation,
  ActivateScourCouponMutationVariables
>
export const AddPartyApprovedLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPartyApprovedLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddPartyApprovedLocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPartyApprovedLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPartyApprovedLocationMutation,
  AddPartyApprovedLocationMutationVariables
>
export const AddPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddPaymentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddPaymentMutation, AddPaymentMutationVariables>
export const AddRecentCityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddRecentCity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addRecentCity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddRecentCityMutation,
  AddRecentCityMutationVariables
>
export const AddUserLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUserLocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserLocationMutation,
  AddUserLocationMutationVariables
>
export const AddUserOccasionalLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserOccasionalLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUserOccasionalLocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserOccasionalLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserOccasionalLocationMutation,
  AddUserOccasionalLocationMutationVariables
>
export const AnswerArticleQuizDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AnswerArticleQuiz' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUsersQuizzesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answerArticleQuiz' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalPoints' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnswerArticleQuizMutation,
  AnswerArticleQuizMutationVariables
>
export const AnswerDailyChallengeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AnswerDailyChallenge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'elapsedTime' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'usedHint' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answerDailyChallenge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'elapsedTime' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'elapsedTime' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usedHint' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'usedHint' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myAnswer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dailyChallenge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challenge' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'explanation' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnswerDailyChallengeMutation,
  AnswerDailyChallengeMutationVariables
>
export const ChangeUserProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeUserProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUserProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'imageUrl' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeUserProfilePictureMutation,
  ChangeUserProfilePictureMutationVariables
>
export const ChangeUsersShouldShowInstructionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeUsersShouldShowInstructions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shouldShowInstructions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUsersShouldShowInstructions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldShowInstructions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldShowInstructions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeUsersShouldShowInstructionsMutation,
  ChangeUsersShouldShowInstructionsMutationVariables
>
export const ChangeUsersShouldShowTutorialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeUsersShouldShowTutorial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shouldShowTutorial' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUsersShouldShowTutorial' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldShowTutorial' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldShowTutorial' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeUsersShouldShowTutorialMutation,
  ChangeUsersShouldShowTutorialMutationVariables
>
export const CreatePartyJoinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePartyJoin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPartyJoin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePartyJoinMutation,
  CreatePartyJoinMutationVariables
>
export const CreatePartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePartyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePartyMutation, CreatePartyMutationVariables>
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>
export const DeletePartyJoinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePartyJoin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyJoinId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePartyJoin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyJoinId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyJoinId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePartyJoinMutation,
  DeletePartyJoinMutationVariables
>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const EndPartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EndParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'endParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EndPartyMutation, EndPartyMutationVariables>
export const EndScourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EndScour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EndScourInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'endScour' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EndScourMutation, EndScourMutationVariables>
export const JoinPartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JoinParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JoinPartyMutation, JoinPartyMutationVariables>
export const LeavePartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LeaveParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaveParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeavePartyMutation, LeavePartyMutationVariables>
export const PausePartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PauseParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PausePartyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pauseParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PausePartyMutation, PausePartyMutationVariables>
export const PickupPinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PickupPin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PickupPinInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickupPin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PickupPinMutation, PickupPinMutationVariables>
export const RateScourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RateScour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RateScourInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateScour' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RateScourMutation, RateScourMutationVariables>
export const RedeemCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RedeemCodeMutation, RedeemCodeMutationVariables>
export const RedeemPinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemPin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RedeemPinInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemPin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RedeemPinMutation, RedeemPinMutationVariables>
export const RemovePartyMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemovePartyMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removePartyMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemovePartyMembersMutation,
  RemovePartyMembersMutationVariables
>
export const RemoveUserFromPartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUserFromParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserFromPartyMutation,
  RemoveUserFromPartyMutationVariables
>
export const ReportBugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportBug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BugReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportBug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportBugMutation, ReportBugMutationVariables>
export const SetPartyAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetPartyAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetPartyAnswersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPartyAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challenge' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'explanation' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetPartyAnswersMutation,
  SetPartyAnswersMutationVariables
>
export const SetPartyPausedAtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetPartyPausedAt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PausePartyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPartyPausedAt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetPartyPausedAtMutation,
  SetPartyPausedAtMutationVariables
>
export const SetShouldNotifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetShouldNotify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'setShouldNotifyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setShouldNotify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetShouldNotifyMutation,
  SetShouldNotifyMutationVariables
>
export const StartPartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartParty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startParty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartPartyMutation, StartPartyMutationVariables>
export const AchievementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Achievements' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'achievements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredScours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AchievementsQuery, AchievementsQueryVariables>
export const ArticleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Article' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'articleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'article' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'articleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'articleId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'challenges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'question' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answerSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'MultipleChoiceAnswerSet',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'multipleChoiceCorrect',
                                    },
                                    name: { kind: 'Name', value: 'correct' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'FreeTextAnswerSet',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'textCorrect',
                                    },
                                    name: { kind: 'Name', value: 'correct' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'FreeNumberAnswerSet',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'numberCorrect',
                                    },
                                    name: { kind: 'Name', value: 'correct' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kind' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'navigation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hint' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArticleQuery, ArticleQueryVariables>
export const CitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Cities' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Article' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrls' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hidden' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Scour' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hidden' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'duration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discounted' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrls' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stops' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'geolocation',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'longitude',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'latitude',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geofence' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geolocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CitiesQuery, CitiesQueryVariables>
export const CityCouponDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CityCoupon' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'couponId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cityCoupon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'couponId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'couponId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'city' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geofence' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'radius' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Article' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrls' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Scour' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrls',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discounted' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stops' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'geolocation',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'longitude',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'latitude',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CityCouponQuery, CityCouponQueryVariables>
export const ClosestPinLocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClosestPinLocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GeoPointInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closestPinLocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aptNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClosestPinLocationsQuery,
  ClosestPinLocationsQueryVariables
>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePictureUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowInstructions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowTutorial' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPoints' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedScours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrls' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'free' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'distance' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'achievements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recentCities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'city' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aptNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dailyChallenges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'played' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentStreak' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxStreak' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quizzes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'article' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'answer' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'correct' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'challengeId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownedScours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scour' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pinsAmount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'redeemed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'redeemedCouponId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shouldNotify' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const PartiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Parties' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scour' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stops' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paused' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pausedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pausedAtStopId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pausedAtChallengeId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pausedAtPage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'profilePictureUrl',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'elapsedTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartiesQuery, PartiesQueryVariables>
export const PartyJoinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PartyJoin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyJoinId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partyJoin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyJoinId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyJoinId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'party' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartyJoinQuery, PartyJoinQueryVariables>
export const PartyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Party' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'partyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'party' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'partyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePictureUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scour' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stops' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'challenges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvedLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challengeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paused' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pausedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pausedAtStopId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pausedAtChallengeId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pausedAtPage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usedHint' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'elapsedTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'IntBox' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'intAnswer' },
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StringBox' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'stringAnswer',
                                    },
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challenge' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'explanation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challengeId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartyQuery, PartyQueryVariables>
export const PinsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Pins' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'redeemed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'achievementId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PinsQuery, PinsQueryVariables>
export const RelevantCitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RelevantCities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GeoPointInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relevantCities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inCity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geofence' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'radius' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Article' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrls' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Scour' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrls',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discounted' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stops' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'geolocation',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'longitude',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'latitude',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RelevantCitiesQuery, RelevantCitiesQueryVariables>
export const ScourCouponDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ScourCoupon' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'couponId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scourCoupon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'couponId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'couponId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScourCouponQuery, ScourCouponQueryVariables>
export const ScourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Scour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'scourId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scour' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scourId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'scourId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalInfo' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'beta' } },
                { kind: 'Field', name: { kind: 'Name', value: 'free' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discounted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discounted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaderboard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'points' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'popups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrls' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geofence' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'radius' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'description' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrls' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastStoryImg' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'order' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'navigationInfo' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challenges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'answerSet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'MultipleChoiceAnswerSet',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'options',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'multipleChoiceCorrect',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'correct',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FreeTextAnswerSet',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'textCorrect',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'correct',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FreeNumberAnswerSet',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'numberCorrect',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'correct',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'navigation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'geolocation',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latitude',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hint' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScourQuery, ScourQueryVariables>
export const ScoursDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Scours' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScoursQuery, ScoursQueryVariables>
export const SuggestedCitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SuggestedCities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GeoPointInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestedCities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nearby' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geofence' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'radius' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Article' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrls' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Scour' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discounted' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrls',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stops' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'geolocation',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'longitude',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'latitude',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Article' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrls' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Scour' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hidden' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discounted' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'level' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'imageUrls',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stops' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'geolocation',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'longitude',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'latitude',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geofence' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'radius' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'geolocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuggestedCitiesQuery,
  SuggestedCitiesQueryVariables
>
export const TodaysDailyChallengeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TodaysDailyChallenge' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'todaysDailyChallenge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dailyChallenge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challenge' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'answerSet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'MultipleChoiceAnswerSet',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'options',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'multipleChoiceCorrect',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'correct',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FreeTextAnswerSet',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'textCorrect',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'correct',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FreeNumberAnswerSet',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'numberCorrect',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'correct',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'navigation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'geolocation',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longitude',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'latitude',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hint' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usersCompleted' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'answer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'IntBox' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'intAnswer',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'StringBox',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'stringAnswer',
                                          },
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'correct' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myAnswer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StringBox' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'stringAnswer',
                                    },
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'IntBox' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'numberAnswer',
                                    },
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'xp' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myDailyChallengesInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'played' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentStreak' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'correct' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxStreak' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TodaysDailyChallengeQuery,
  TodaysDailyChallengeQueryVariables
>
export const UserEmailByUsernameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserEmailByUsername' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEmailByUsername' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'username' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'username' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserEmailByUsernameQuery,
  UserEmailByUsernameQueryVariables
>
