import { VersionService } from '../services/versionService'
import Message from './JoinParty/Message'
import Modal from './JoinParty/Modal'
import ModalTitle from './JoinParty/ModalTitle'
import ModalBackgroundComponent from './Modal/ModalBackgroundComponent'

interface Props {
  versionService: typeof VersionService
}

const NotSupportedClientModal = ({ versionService }: Props) => (
  <ModalBackgroundComponent classnames={{ background: 'blue' }}>
    <Modal>
      <ModalTitle>Unsupported Version</ModalTitle>
      <Message theme="no-margin-bottom">
        Please update City Scour to the latest version. You are currently using
        v{versionService.getClientVersion()}, while the minimum supported
        version is v{versionService.getSmallestSupportedVersion()}
      </Message>
    </Modal>
  </ModalBackgroundComponent>
)

export default NotSupportedClientModal
