import { AFInit, AppsFlyer } from 'appsflyer-capacitor-plugin'
import { useEffect } from 'react'
import { APPSYFLYER_DEV_KEY, APPSYFLYER_IOS_ID } from '../config'
import { useUserContext } from '../context/userContext'
import { runAppsFlyer } from '../util'

const useAppsFlyer = () => {
  const { user: authUser } = useUserContext()

  useEffect(() => {
    if (APPSYFLYER_IOS_ID && APPSYFLYER_DEV_KEY) {
      const afConfig: AFInit = {
        appID: APPSYFLYER_IOS_ID,
        devKey: APPSYFLYER_DEV_KEY,
      }

      runAppsFlyer(() => AppsFlyer.initSDK(afConfig))
    }
  }, [])

  useEffect(() => {
    if (authUser === null) return
    runAppsFlyer(() => AppsFlyer.setCustomerUserId({ cuid: authUser.uid }))
  }, [authUser])
}

export default useAppsFlyer
