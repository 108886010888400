import { IonLoading } from '@ionic/react'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { sentryWrapper } from '../util'
import Message from './JoinParty/Message'
import Modal from './JoinParty/Modal'
import ModalBackgroundComponent from './Modal/ModalBackgroundComponent'

const LONG_LOADING_WARNING_TIME_MS = 15 * 1000

const Loading: React.FC<{
  debugName: string
  modal?: boolean
  message?: string
  context: any
}> = ({ debugName, modal = false, message = '', context }) => {
  useEffect(() => {
    const handle = setTimeout(() => {
      sentryWrapper().captureMessage('Loading displayed for over 15 seconds', {
        level: Sentry.Severity.Warning,
        extra: { debugName, context },
      })
      console.log(`Loading displayed for over 15 seconds for ${debugName}`)
    }, LONG_LOADING_WARNING_TIME_MS)

    return () => clearTimeout(handle)
  }, [debugName, context])

  return !modal ? (
    <IonLoading id={debugName ? `__debug_${debugName}` : ''} isOpen={true} />
  ) : (
    <ModalBackgroundComponent classnames={{ background: 'blue' }}>
      <Modal>
        <Message>{message}</Message>
      </Modal>
    </ModalBackgroundComponent>
  )
}

export default Loading
