import { firebase } from '../firebase'

const CONFIG_MAINTENANCE_MODE = 'maintenanceMode'

export class MaintenanceService {
  private static remoteConfig = firebase.remoteConfig()

  static async getIsMaintenanceMode(): Promise<boolean> {
    await this.remoteConfig.fetchAndActivate()
    return this.remoteConfig.getBoolean(CONFIG_MAINTENANCE_MODE)
  }
}
