import { compare, validate } from 'compare-versions'
import { APP_VERSION } from '../config'
import { firebase } from '../firebase'
import { sentryWrapper } from '../util'

const CONFIG_SMALLEST_SUPPORTED_VERSION = 'smallestSupportedVersion'

export class VersionService {
  private static clientVersion = APP_VERSION
  private static smallestSupportedVersion = APP_VERSION
  private static remoteConfig = firebase.remoteConfig()

  static getClientVersion(): string {
    return this.clientVersion
  }

  static getSmallestSupportedVersion(): string {
    return this.smallestSupportedVersion
  }

  static async getIsClientSupported(): Promise<boolean> {
    await this.remoteConfig.fetchAndActivate()
    this.smallestSupportedVersion = this.remoteConfig.getString(
      CONFIG_SMALLEST_SUPPORTED_VERSION
    )

    if (!validate(this.clientVersion)) {
      sentryWrapper().captureException(
        new Error(
          `Client's version of ${this.clientVersion} is not a valid version identifier`
        )
      )
      return true
    } else if (!validate(this.smallestSupportedVersion)) {
      sentryWrapper().captureException(
        new Error(
          `Smallest supported version of ${this.smallestSupportedVersion} is not a valid version identifier`
        )
      )
      return true
    }

    return compare(this.clientVersion, this.smallestSupportedVersion, '>=')
  }
}
