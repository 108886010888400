import { Component } from 'react'
import ErrorPage from '../pages/ErrorPage'

class ErrorBoundary extends Component {
  state = {
    error: false,
  }

  componentDidCatch() {
    this.setState({
      error: true,
    })
  }

  render() {
    if (this.state.error) {
      return <ErrorPage crash={true} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
