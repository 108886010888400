import { useEffect, useState } from 'react'
import { MaintenanceService } from '../services/maintenanceService'
import { VersionService } from '../services/versionService'

const useRemoteConfigServices = () => {
  const [clientSupported, setClientSupported] = useState(true)
  const [maintenanceMode, setMaintenanceMode] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      const isMaintenanceMode = await MaintenanceService.getIsMaintenanceMode()
      const isSupported = await VersionService.getIsClientSupported()
      setClientSupported(isSupported)
      setMaintenanceMode(isMaintenanceMode)
    }

    loadData()
  }, [])

  return {
    clientSupported,
    maintenanceMode,
    VersionService,
  }
}

export default useRemoteConfigServices
