import styled from 'styled-components'

const ModalTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;

  font-family: var(--font-bold);
  font-size: 24px;
  line-height: 1.33;

  text-align: center;

  color: var(--less-dark-blue);

  margin-bottom: ${({ theme }) => theme === 'qr' && '40px'};

  @media screen and (max-height: 667px), screen and (max-width: 374px) {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
`

export default ModalTitle
