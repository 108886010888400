import path from 'path'
import { generatePath, matchPath } from 'react-router-dom'

const jn = path.join

export const ROOT = '/'
export const VIDEO_TUTORIAL = '/videoTutorial'
export const START = '/getStarted'
export const GREETING = '/greeting'
export const LOGIN_EMAIL = '/login/email'
export const SIGNUP = '/signup/email'
export const RESET = '/resetPassword'
export const LOGOUT = '/logout'
export const TABS = '/tabs'
export const TABS_PROFILE = jn(TABS, '/profile')
export const TABS_JOIN = jn(TABS, '/join')
export const TABS_JOURNAL = jn(TABS, '/journal')
export const TABS_HOME = jn(TABS, '/home')
export const CITY_PICK = '/cityPick'
export const ARTICLE = jn(TABS_HOME, '/articles/:articleId')
export const SCOUR = jn(TABS_HOME, '/scours/:scourId/')
export const SCOUR_PAYMENT = '/scours/:scourId/payment'
export const SCOUR_INFO = jn(SCOUR, '/scour/info')
export const SCOUR_PARTY = jn(SCOUR, '/party')
export const SCOUR_PARTY_OPTION = '/scours/:scourId/party/options'
export const SCOUR_PARTY_CREATE = '/scours/:scourId/party/create/:resume?'
export const SCOUR_PARTY_JOIN = jn(SCOUR_PARTY, '/join')
export const SCOUR_PARTY_WAITING = '/scours/:scourId/party/:partyId/waiting'
export const SCOUR_PARTY_SOLO = '/scours/:scourId/solo'
export const SCOUR_STOP = '/scour/:scourId/:partyId/stops/:stopIndex'
export const SCOUR_STOP_MAP =
  '/scour/:scourId/:partyId?/stops/:stopIndex/map/:additional?'
export const SCOUR_CHALLENGE =
  '/scour/:scourId/:partyId/stops/:stopIndex/challenges/:challengeIndex'
export const SCOUR_END = '/scour/:scourId/end/:partyId/:additional?'
export const SCOUR_LOADING =
  '/scour/:scourId/:partyId/loading/:stopIndex/:challengeIndex/:stoppedAtPage'
export const DAILY_CHALLENGE = '/dailychallenge'
export const DAILY_CHALLENGE_RESULTS = '/dailychallenge/results'

/**
 * Populates path with path params starting with ':'
 * @param path path with path params
 * @param params object of params and values
 * @returns path with populated params
 */
export function populate(path: string, params: Record<string, any>): string {
  return generatePath(path, params)
}

export function doesMatch(route: string, path: string) {
  return !!matchPath(path, { path: route, exact: true })
}
