import { App, AppState } from '@capacitor/app'
import { useEffect, useState } from 'react'
import useUserRecordLocation from './useUserRecordLocation'

const useAppStateChange = () => {
  const [isAppActive, setIsAppActive] = useState(true)

  const saveUserLocation = useUserRecordLocation()

  useEffect(() => {
    App.addListener('appStateChange', (res: AppState) => {
      setIsAppActive(res.isActive)
      res.isActive && saveUserLocation()
    })
    return () => {
      App.removeAllListeners()
    }
  }, [saveUserLocation])

  return isAppActive
}

export default useAppStateChange
