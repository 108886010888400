import { useSubscription } from '@apollo/client'
import React from 'react'
import { PARTY_TIMEOUT_SUBSCRIPTION } from '../graphql/subscriptions/partyTimeoutSubscription'

const PartySubscriptionComponent: React.FC = () => {
  useSubscription(PARTY_TIMEOUT_SUBSCRIPTION)

  return null
}

export default PartySubscriptionComponent
