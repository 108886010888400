import { Capacitor } from '@capacitor/core'
import {
  Auth,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/remote-config'
import 'firebase/compat/storage'
import {
  EMULATOR_AUTH,
  EMULATOR_FIRESTORE_HOST,
  EMULATOR_FIRESTORE_PORT,
  EMULATOR_FUNCTIONS_HOST,
  EMULATOR_FUNCTIONS_PORT,
  ENVIRONMENT,
  FIREBASE,
} from './config'

export type DocumentSnapshot<T> = firebase.firestore.DocumentSnapshot<T>

const ERROR_MESSAGES: Record<string, string> & { default: string } = {
  'auth/wrong-password': 'Incorrect email or password',
  'auth/invalid-email': 'Invalid email address',
  'auth/user-not-found': 'Incorrect email or password',
  'auth/too-many-requests': 'Too many requests',
  default: 'An error occured',
}

const app = firebase.initializeApp(FIREBASE)

const firestore = firebase.firestore()
firestore.enablePersistence()

// https://web.archive.org/web/20210921144058/https://harryherskowitz.com/2021/08/23/firebase-capacitor.html
let auth: Auth
if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(app, {
    persistence: indexedDBLocalPersistence,
  })
} else {
  auth = getAuth()
}

function getFirebaseErrorMessage(code: string): string {
  return ERROR_MESSAGES[code] || ERROR_MESSAGES.default
}

if (ENVIRONMENT === 'development') {
  if (EMULATOR_AUTH) {
    firebase.auth().useEmulator(EMULATOR_AUTH)
  }
  if (EMULATOR_FIRESTORE_HOST && EMULATOR_FIRESTORE_PORT) {
    firestore.useEmulator(
      EMULATOR_FIRESTORE_HOST,
      Number(EMULATOR_FIRESTORE_PORT)
    )
  }
  if (EMULATOR_FUNCTIONS_HOST && EMULATOR_FUNCTIONS_PORT) {
    firebase
      .functions()
      .useEmulator(EMULATOR_FUNCTIONS_HOST, Number(EMULATOR_FUNCTIONS_PORT))
  }
}

export { firebase, firestore, getFirebaseErrorMessage, auth }
