import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { BACKEND_LINK, WS_BACKEND_LINK } from '../config'
import { auth } from '../firebase'
import { sentryWrapper } from '../util'

export class apolloService {
  private static errorLink: ApolloLink = onError(
    ({ graphQLErrors, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          sentryWrapper().captureException(
            new Error(`Graphql error: ${message}.`)
          )
          sentryWrapper().configureScope((scope) => {
            scope.setTag(
              'transaction_id',
              operation.getContext().headers['x-transaction-id']
            )
          })
        })
      }
    }
  )

  private static wsLink: ApolloLink = from([
    this.errorLink,
    new GraphQLWsLink(
      createClient({
        url: WS_BACKEND_LINK,
        connectionParams: async () => {
          return {
            authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
          }
        },
      })
    ),
  ])

  private static httpLink: ApolloLink = from([
    this.errorLink,
    new HttpLink({ uri: BACKEND_LINK }),
  ])

  private static splitLink: ApolloLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    this.wsLink,
    this.httpLink
  )

  private static authLink: ApolloLink = setContext(async (_, { headers }) => {
    const token = await auth.currentUser?.getIdToken()
    const transactionId = Math.random().toString(36).substr(2, 9)

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-transaction-id': transactionId,
      },
    }
  })

  private static client: ApolloClient<NormalizedCacheObject> = new ApolloClient(
    {
      cache: new InMemoryCache(),
      link: this.authLink.concat(this.splitLink),
    }
  )

  static getClient(): ApolloClient<NormalizedCacheObject> {
    return this.client
  }
}
