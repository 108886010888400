import { useEffect } from 'react'
import { useUserContext } from '../context/userContext'
import { runOneSignal } from '../util'

const useOneSignal = () => {
  const { user: authUser } = useUserContext()

  useEffect(() => {
    runOneSignal((OneSignal) =>
      OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_ID || '')
    )
  }, [])

  useEffect(() => {
    if (authUser === null) return
    runOneSignal((OneSignal) => OneSignal.setExternalUserId(authUser.uid))
  }, [authUser])

  useEffect(() => {
    runOneSignal((OneSignal) =>
      OneSignal.addTrigger('authenticated', authUser ? 'true' : 'false')
    )

    const handle = authUser
      ? setInterval(() => {
          runOneSignal((OneSignal) => {
            OneSignal.getTriggerValueForKey(
              'authenticated_session_duration',
              (value: string | null) => {
                const valueNumber = Number(value)
                const currentAuthenticatedSessionDuration = Number.isNaN(
                  valueNumber
                )
                  ? 0
                  : valueNumber
                const authenticatedSessionDuration =
                  currentAuthenticatedSessionDuration + 1

                OneSignal.addTrigger(
                  'authenticated_session_duration',
                  authenticatedSessionDuration.toString()
                )
              }
            )
          })
        }, 1000)
      : null

    return () => {
      handle && clearInterval(handle)
    }
  }, [authUser])
}

export default useOneSignal
