import styled from 'styled-components'

const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 15px;

  color: ${({ theme: { type } }) => {
    if (type === 'unavailable') return 'var(--light-grey)'
    else return 'var(--white)'
  }};
  background: ${({ theme: { type } }) => {
    if (type === 'blue') return 'var( --blue-button-background)'
    if (type === 'unavailable') return 'var(--unavailable-button-background)'
    if (type === 'social-g') return 'var(--bright-blue)'
    if (type === 'social-apple') return '#222'
    else return 'var(--big-button-background)'
  }};

  font-size: ${({ theme: { fontSize } }) => fontSize || '20px'};
  font-family: var(--font-bold);
  line-height: 2.2;
  text-transform: capitalize;

  @media screen and (max-height: 667px), screen and (max-width: 374px) {
    font-size: ${({ theme: { fontSize } }) =>
      fontSize ? `calc(${fontSize} - 2px)` : '18px'};
  }

  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    font-size: ${({ theme: { fontSize } }) =>
      fontSize ? `calc(${fontSize} - 4px)` : '16px'};
  }
`

export default CustomButton
