import { FC, useRef } from 'react'
import { ModalBackgroundProps } from '../../propTypes'
import ModalBackground from './ModalBackground'

const ModalBackgroundComponent: FC<ModalBackgroundProps> = ({
  classnames,
  children,
  handleModalClose = () => {},
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  return (
    <ModalBackground
      ref={modalRef}
      onClick={(e) => {
        if (e.target !== modalRef.current) return
        handleModalClose()
      }}
      theme={classnames}
    >
      {children}
    </ModalBackground>
  )
}

export default ModalBackgroundComponent
