import styled from 'styled-components'

const BigButton = styled.div`
  position: ${({ theme: { position } }) => position};
  bottom: ${({ theme: { bottom } }) => bottom};
  left: ${({ theme: { left } }) => left};
  transform: ${({ theme: { transform } }) => transform};
  z-index: ${({ theme: { zIndex } }) => zIndex};

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ theme: { width } }) => width || '100%'};
  height: ${({ theme: { height } }) => height || '60px'};

  opacity: 1;

  border-radius: 15px;

  margin-bottom: ${({ theme: { marginBottom } }) => marginBottom};
  margin-top: ${({ theme: { marginTop } }) => marginTop};
  margin-right: ${({ theme: { marginRight } }) => marginRight};

  visibility: ${({ theme: { hide } }) => hide === 'hide' && 'hidden'};
  opacity: ${({ theme: { hide } }) => hide === 'hide' && '0'};
  transition: ${({ theme: { hide } }) =>
    hide === 'hide' && 'opacity 100ms ease'};

  @media screen and (max-height: 667px), screen and (max-width: 374px) {
    height: ${({ theme: { height } }) => height || '55px'};
  }

  @media screen and (max-height: 568px), screen and (max-width: 320px) {
    height: ${({ theme: { height } }) => height || '50px'};
  }
`

export default BigButton
