import { useLazyQuery, useMutation } from '@apollo/client'
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import axios from 'axios'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { GOOGLE_API_KEY } from '../config'
import { useUserContext } from '../context/userContext'
import {
  AddUserLocationDocument,
  GeoPoint,
  PreciseGeoPointInput,
  RelevantCitiesDocument,
  RelevantCitiesQuery,
} from '../generated'
import UIContext from './context'
import { usePartyContext } from './partyContext'

export interface GeolocationContext {
  geolocation: GeoPoint | string | undefined
  city: RelevantCitiesQuery['relevantCities']['cities'][number]
  setCity: Dispatch<
    SetStateAction<RelevantCitiesQuery['relevantCities']['cities'][number]>
  >
  userCity: string | undefined
  citiesAround: RelevantCitiesQuery['relevantCities']['cities'] | undefined
  requestGeolocation: boolean
  setRequestGeolocation: Dispatch<SetStateAction<boolean>>
  defaultCity: RelevantCitiesQuery['relevantCities']['cities'][number]
  geolocationRecords: PreciseGeoPointInput[] | undefined
  setGeolocationRecords: Dispatch<
    SetStateAction<PreciseGeoPointInput[] | undefined>
  >
}

const defaultCity: RelevantCitiesQuery['relevantCities']['cities'][number] = {
  name: 'Unknown',
  geofence: {
    geolocation: { latitude: 38, longitude: -95 },
    radius: 0,
  },
  tiles: [],
  id: '',
}

const Context = createContext<GeolocationContext>({
  geolocation: undefined,
  city: defaultCity,
  defaultCity,
  userCity: undefined,
  citiesAround: undefined,
  setCity: () => {},
  requestGeolocation: false,
  setRequestGeolocation: () => {},
  geolocationRecords: undefined,
  setGeolocationRecords: () => {},
})

export const GeolocationContextProvider: FC = ({ children }) => {
  const { currentUser: user } = useUserContext()
  const { partyId } = usePartyContext()
  const { cityCoupon } = useContext(UIContext)

  const [requestGeolocation, setRequestGeolocation] = useState(false)
  const [geolocation, setGeolocation] = useState<GeoPoint | string>()
  const [city, setCity] =
    useState<RelevantCitiesQuery['relevantCities']['cities'][number]>(
      defaultCity
    )
  const [userCity, setUserCity] = useState<string>()
  const [citiesAround, setCitiesAround] =
    useState<RelevantCitiesQuery['relevantCities']['cities']>()
  const [runCityEffect, setRunCityEffect] = useState(false)
  const [geolocationRecords, setGeolocationRecords] =
    useState<PreciseGeoPointInput[]>()

  const [getCities] = useLazyQuery(RelevantCitiesDocument)

  const [addUserPartyLocation] = useMutation(AddUserLocationDocument)

  const checkGeolocation = useCallback(async (partyId: string | null) => {
    try {
      Capacitor.isNativePlatform() && (await Geolocation.requestPermissions())

      const pos = await Geolocation.getCurrentPosition({
        enableHighAccuracy: false,
        timeout: 10000,
      })

      setGeolocation(pos?.coords)

      if (partyId) {
        setGeolocationRecords((prev) =>
          prev
            ? [
                ...prev,
                {
                  latitude: pos?.coords?.latitude ?? 0,
                  longitude: pos?.coords?.longitude ?? 0,
                  timestamp: new Date(),
                },
              ]
            : [
                {
                  latitude: pos?.coords?.latitude ?? 0,
                  longitude: pos?.coords?.longitude ?? 0,
                  timestamp: new Date(),
                },
              ]
        )
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown Error'
      setGeolocation(message)
    }
  }, [])

  useEffect(() => {
    if (!user?.address?.zipCode) return
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${user.address.zipCode}&sensor=true&key=${GOOGLE_API_KEY}`
      )
      .then((data) => {
        const requiredData = data.data.results[0].address_components.filter(
          (component: any) => {
            const { types } = component

            return (
              types.includes('locality') ||
              types.includes('administrative_area_level_1') ||
              types.includes('postal_town') ||
              types.includes('country')
            )
          }
        )

        const resCityName = requiredData.find(
          (item: any) =>
            item.types.includes('locality') ||
            item.types.includes('postal_town')
        )?.long_name

        const resCityState = requiredData.find(
          (item: any) =>
            item.types.includes('administrative_area_level_1') ||
            item.types.includes('country')
        )?.short_name

        setUserCity(`${resCityName}, ${resCityState}`)
      })
  }, [user?.address?.zipCode])

  useEffect(() => {
    if (!partyId || geolocationRecords?.length !== 6) return
    addUserPartyLocation({
      variables: {
        input: {
          partyId,
          geolocation: geolocationRecords,
        },
      },
    }).then(() => {
      setGeolocationRecords(undefined)
    })
  }, [partyId, geolocationRecords])

  useEffect(() => {
    if (
      !user?.id ||
      !geolocation ||
      typeof geolocation === 'string' ||
      (city && city.name !== defaultCity.name) ||
      cityCoupon
    )
      return
    getCities({
      variables: {
        input: {
          latitude: geolocation?.latitude ?? 0,
          longitude: geolocation?.longitude ?? 0,
        },
      },
    })
      .then((res) => {
        const relevantCities = res.data?.relevantCities
        if (relevantCities?.inCity) {
          setCity(relevantCities.cities[0])
          setRunCityEffect(false)
        } else {
          setCitiesAround(relevantCities?.cities)
          setRunCityEffect(true)
        }
      })
      .catch((e) => {
        setRunCityEffect(true)
        console.log(e.message)
      })
  }, [geolocation, user?.id, city, cityCoupon])

  useEffect(() => {
    if (!requestGeolocation) return
    checkGeolocation(partyId)

    const id = setInterval(() => {
      checkGeolocation(partyId)
    }, 10000)

    return () => clearInterval(id)
  }, [requestGeolocation, partyId])

  useEffect(() => {
    if (
      geolocation === undefined ||
      typeof geolocation === 'string' ||
      !runCityEffect
    )
      return

    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
          geolocation?.latitude ?? 0
        },${geolocation?.longitude ?? 0}&key=${GOOGLE_API_KEY}`
      )
      .then((data) => {
        const requiredData = data.data.results[0].address_components.filter(
          (component: any) => {
            const { types } = component

            return (
              types.includes('locality') ||
              types.includes('administrative_area_level_1') ||
              types.includes('postal_town') ||
              types.includes('country')
            )
          }
        )

        const resCityName = requiredData.find(
          (item: any) =>
            item.types.includes('locality') ||
            item.types.includes('postal_town')
        )?.long_name

        const resCityState = requiredData.find(
          (item: any) =>
            item.types.includes('administrative_area_level_1') ||
            item.types.includes('country')
        )?.short_name

        setCity({
          name:
            resCityName && resCityState
              ? `${resCityName}, ${resCityState}`
              : 'Unknown',
          geofence: {
            geolocation,
            radius: 0,
          },
          tiles: [],
          id: '',
        })
        setRunCityEffect(false)
      })
  }, [geolocation, runCityEffect])

  const state: GeolocationContext = {
    geolocation,
    city,
    setCity,
    userCity,
    citiesAround,
    requestGeolocation,
    setRequestGeolocation,
    defaultCity,
    geolocationRecords,
    setGeolocationRecords,
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
export const useGeolocationContext = () => useContext(Context)
