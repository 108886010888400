export class EventBus {
  static channels: Record<string, (() => void)[] | undefined> = {}

  static subscribe(channelName: string, listener: () => void): void {
    if (!this.channels[channelName]) {
      this.channels[channelName] = [() => {}]
    }
    this.channels[channelName]?.push(listener)
  }

  static unsubscribe(channelName: string, listener: () => void): void {
    this.channels[channelName]?.filter((l) => l !== listener)
  }

  static publish(channelName: string): void {
    const channel = this.channels[channelName]
    if (!channel || !channel.length) {
      return
    }

    channel.forEach((listener: () => void) => listener())
  }
}
