function getOptionalEnv(key: string): string | undefined {
  return process.env[key]
}

function getEnv(key: string): string {
  const val = getOptionalEnv(key)
  if (!val) throw new Error(`Environment variable '${key}' must be set`)
  return val
}

function getOptionalEnvBool(key: string): boolean | undefined {
  const env = getOptionalEnv(key)?.toLowerCase()
  return env === undefined ? undefined : env === 'true'
}

function getEnvironment(): 'production' | 'development' | 'staging' {
  let env = getEnv('REACT_APP_ENVIRONMENT')
  if (env === 'local') env = 'development'
  if (env !== 'production' && env !== 'development' && env !== 'staging')
    throw new Error('Invalid environment value')
  return env
}

export const MAPBOX_TOKEN = getEnv('REACT_APP_MAPBOX_TOKEN')
export const MAPBOX_STYLE = getEnv('REACT_APP_MAPBOX_STYLE')
export const DSN = getEnv('REACT_APP_SENTRY_DSN')
export const MIXPANEL_TOKEN = getEnv('REACT_APP_MIXPANEL_TOKEN')
export const MAILCHIMP_URL = getOptionalEnv('REACT_APP_MAILCHIMP_URL')
export const PRIVACY_POLICY_URL = getEnv('REACT_APP_PRIVACY_POLICY_URL')
export const EULA_URL = getEnv('REACT_APP_EULA_URL')
export const TESTING_USER_EMAIL = getEnv('REACT_APP_TESTING_USER_EMAIL')
export const REACT_APP_OVERRIDE_LOGGING =
  getOptionalEnvBool('REACT_APP_OVERRIDE_LOGGING') || false
export const HJID = Number(getOptionalEnv('REACT_APP_HOTJAR_ID'))
export const HJSV = Number(getOptionalEnv('REACT_APP_HOTJAR_SNIPPET_VERSION'))
export const GOOGLE_API_KEY = getEnv('REACT_APP_GOOGLE_API_KEY')
export const READ_ATTEMPTS_FOR_FIVE_MINUTES = Number(
  getEnv('REACT_APP_READ_ATTEMPTS_FOR_FIVE_MINUTES')
)
export const WRITE_ATTEMPTS_FOR_FIVE_MINUTES = Number(
  getEnv('REACT_APP_WRITE_ATTEMPTS_FOR_FIVE_MINUTES')
)
export const READ_ATTEMPTS_LIMIT = Number(
  getEnv('REACT_APP_READ_ATTEMPTS_LIMIT')
)
export const WRITE_ATTEMPTS_LIMIT = Number(
  getEnv('REACT_APP_WRITE_ATTEMPTS_LIMIT')
)
export const RELEASE = getEnv('REACT_APP_RELEASE')
export const APP_VERSION = getEnv('REACT_APP_VERSION')
export const ENVIRONMENT = getEnvironment()
export const BACKEND_LINK = getEnv('REACT_APP_BACKEND_LINK')
export const WS_BACKEND_LINK = getEnv('REACT_APP_WS_BACKEND_LINK')
export const EMULATOR_AUTH = getOptionalEnv('REACT_APP_EMULATOR_AUTH')
export const EMULATOR_FIRESTORE_HOST = getOptionalEnv(
  'REACT_APP_EMULATOR_FIRESTORE_HOST'
)
export const EMULATOR_FIRESTORE_PORT = getOptionalEnv(
  'REACT_APP_EMULATOR_FIRESTORE_PORT'
)
export const EMULATOR_FUNCTIONS_HOST = getOptionalEnv(
  'REACT_APP_EMULATOR_FUNCTIONS_HOST'
)
export const EMULATOR_FUNCTIONS_PORT = getOptionalEnv(
  'REACT_APP_EMULATOR_FUNCTIONS_PORT'
)
export const APPSYFLYER_IOS_ID = getOptionalEnv('REACT_APP_APPSYFLYER_IOS_ID')
export const APPSYFLYER_DEV_KEY = getOptionalEnv('REACT_APP_APPSYFLYER_DEV_KEY')

export const FIREBASE = {
  apiKey: getEnv('REACT_APP_FIREBASE_API_KEY'),
  authDomain: getEnv('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  projectId: getEnv('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getEnv('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnv('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
  appId: getEnv('REACT_APP_FIREBASE_APP_ID'),
  measurementId: getEnv('REACT_APP_FIREBASE_MEASUREMENT_ID'),
}

export const RECEIPT_VALIDATOR_URL = getEnv('REACT_APP_RECEIPT_VALIDATOR_URL')
