import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import BigButton from '../components/BigButton'
import CustomButton from '../components/CustomButton'
import Description from '../components/ErrorPage/Description'
import DescriptionTitle from '../components/ErrorPage/DescriptionTitle'
import ErrorIcon from '../components/ErrorPage/ErrorIcon'
import Wrapper from '../components/ErrorPage/Wrapper'
import UIContext from '../context/context'
import useHideTabs from '../hooks/useHideTabs'
import { ROOT } from '../routes'

interface Props {
  crash?: boolean
}

const ErrorPage: React.FC<Props> = ({ crash }) => {
  const { setShowTabs } = useContext(UIContext)

  const history = useHistory()

  useHideTabs()

  const handleClick = () => {
    setShowTabs(true)
    history.replace(ROOT)
  }

  return (
    <Wrapper>
      <ErrorIcon icon={faCircleExclamation} />
      <Description>
        <DescriptionTitle>Oops!</DescriptionTitle>
        <br />
        Something went wrong.
        {crash && (
          <>
            <br />
            Please close and reopen the app.
          </>
        )}
      </Description>
      {!crash && (
        <BigButton>
          <CustomButton onClick={handleClick}>Continue</CustomButton>
        </BigButton>
      )}
    </Wrapper>
  )
}

export default ErrorPage
